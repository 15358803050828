<template>
  <b-container>
    <h3>temporarily down for maintenance . . .</h3>
    <p>please come back in a while ...</p>
  </b-container>
</template>
<script>
export default {
  title: 'Site down for maintenance'
}
</script>
