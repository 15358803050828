<template>
  <div class="formfieldBorder">
    <b-form-group
      label-for="numberinput"
      :label="block.label"
      :description="block.description"
    >
      <b-form-input
        id="numberinput"
        type="number"
        size="lg"
        v-model="fieldvalue"
        :state="fieldvalue != null"
        :placeholder="block.placeholder"
        :min="block.min"
        :max="block.max"
      ></b-form-input>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'NumberInput',
  data() {
    return {
      fieldvalue: null
    }
  }
}
</script>
