<template>
  <div class="formfieldBorder">
    <b-form-group
      :label="block.label"
      v-slot="{ ariaDescribedby }"
      label-for="buttons"
    >
      <b-form-radio-group
        id="buttons"
        v-model="fieldvalue"
        :options="block.options"
        :aria-describedby="ariaDescribedby"
        stacked
        :state="state"
        size="lg"
      >
        <b-form-invalid-feedback :state="state"></b-form-invalid-feedback>
        <b-form-valid-feedback :state="state"></b-form-valid-feedback>
      </b-form-radio-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'RadioInput',
  data() {
    return {
      fieldvalue: null
    }
  },
  computed: {
    state() {
      return Boolean(this.fieldvalue)
    }
  }
}
</script>
