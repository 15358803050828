<template>
  <b-container>
    <Error v-if="error" :error="error" />
    <h3>Post a question, a request, what you learned</h3>
    <p v-if="!this.status" class="warning">
      Note: Only project participants may submit posts using this form.
    </p>
    <b-button class="w-50 m-4" size="lg" v-b-modal.how-to-post variant="info"
      >Review the procedure</b-button
    >

    <b-modal id="how-to-post" title="Post" ok-only>
      <p class="my-4">
        Posts (blog posts, consisting of text, pictures) are viewable by project
        participants, or by anyone if you uncheck the "private" checkbox. A
        rich-text toolbar is provided for formatting, allowing you to insert and
        resize pictures, embed video, format a bulleted list, etc. NOTE:
        pictures cannot be simply pasted in. To insert a picture, it must be
        uploaded from a file. To resize a picture, click on the picture after
        you have uploaded it and drag the handles.
      </p>
      <p class="my-4">
        Posts should relate to your project. Please strive for accuracy and
        truth.
      </p>
    </b-modal>
    <b-form @submit.prevent="onSubmit">
      <b-form-group label-for="input-1" label="Subject or question">
        <b-form-input
          id="input-1"
          size="lg"
          autofocus
          type="text"
          v-model="title"
          placeholder="your subject or question"
          trim
          required
          tabindex="1"
        />
      </b-form-group>
      <quill-editor
        ref="quillEdit"
        v-model="content"
        :options="editorOption"
        tabindex="2"
      />
      <input type="file" @change="uploadImage" id="file" hidden />
      <b-form-checkbox size="lg" v-model="privat" class="mb-2"
        >uncheck to allow public view</b-form-checkbox
      >
      <b-button
        v-if="this.status"
        type="submit"
        variant="dark"
        size="lg"
        class="m-5 w-75"
      >
        <b-spinner small v-if="submitting"></b-spinner>&nbsp;&nbsp;Submit
      </b-button>
    </b-form>
    <Error v-if="error" :error="error" />
  </b-container>
</template>

<script>
import api from '@/api.js'
import GlobalComponents from '@/globalComponents.js'
import { contextMixin } from '@/mixins/context.js'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
import Quill from 'quill'
import { quillEditor } from 'vue-quill-editor'
import ImageResize from 'quill-image-resize-vue'
Quill.register('modules/imageResize', ImageResize)
export default {
  title: 'New post',
  name: 'PostNew',
  mixins: [contextMixin],
  components: { quillEditor },

  data() {
    return {
      title: '',
      content: '',
      image: null,
      isEditing: false,
      privat: true,
      submitting: false,
      error: false,
      editorOption: {
        modules: {
          toolbar: {
            container: [
              ['bold', 'italic', 'underline', 'strike'],
              ['blockquote'],
              [{ list: 'ordered' }, { list: 'bullet' }],
              [{ indent: '-1' }, { indent: '+1' }],
              [{ size: ['small', false, 'large', 'huge'] }],
              [{ header: [1, 2, 3, 4, false] }],
              [{ font: [] }],
              [{ align: [] }],
              ['image'],
              ['link', 'video']
            ],
            handlers: {
              image: function () {
                document.getElementById('file').click()
              }
            }
          },
          imageResize: {
            modules: ['Resize', 'DisplaySize', 'Toolbar']
          }
        }
      }
    }
  },
  methods: {
    uploadImage(event) {
      var selectedFile = event.target.files[0]
      var fd = new FormData()
      fd.append('image', selectedFile)
      fd.append('owner', 1)

      for (var pair of fd.entries()) {
        console.log(pair[0] + ', ' + pair[1])
      }
      //upload image to server
      api
        .post('/images/', fd)
        .then((response) => {
          console.log('RESPONSE.data: ', response.data)
          // this code to set your position cursor
          const range = this.$refs.quillEdit.quill.getSelection()
          // this code to set image on your server to quill editor
          this.$refs.quillEdit.quill.insertEmbed(
            range.index,
            'image',
            `${response.data.image}`
          )
        })
        .catch((e) => {
          console.log('POST error: ', e)
          this.error = error
        })
    },
    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },
    onSubmit(evt) {
      evt.preventDefault()
      this.submitting = true
      const fd = new FormData()
      fd.append('author', this.authObserver.my_id)
      fd.append('project', this.projectID)
      fd.append('title', this.title)
      fd.append('body', this.content)
      fd.append('private', this.privat)
      for (var pair of fd.entries()) {
        console.log(pair[0] + ': ' + pair[1])
      }
      api
        .post('/posts-new/', fd)
        .then((response) => {
          console.log('POST RESPONSE.data: ', response.data)
          this.$router.push(
            '/' + this.projectName + '/posts/' + response.data.id
          )
        })
        .catch((error) => {
          console.log('PostNew error: ', error.response)
          this.error = error
          this.submitting = false
        })
    }
  },
  computed: {
    editor() {
      return this.$refs.quillEdit.quill
    }
  },
  mounted() {
    console.log('this is current quill instance object', this.editor)
  }
}
</script>
<style>
.ql-container {
  font-size: 1.3rem;
  border-radius: 5px;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 123, 255, 0.3),
    0 6px 20px 0 rgba(0, 123, 255, 0.3);
  margin: 8px;
  padding: 4px;
}
.ql-container:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 123, 255, 0.8),
    0 6px 20px 0 rgba(0, 123, 255, 0.6);
}
p.warning {
  color: rgb(138, 4, 11);
  font-size: 1.4rem;
  font-style: italic;
}
</style>
