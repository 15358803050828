<template>
  <div>
    <b-button class="w-50 m-4" size="lg" v-b-modal.how-to variant="info">{{
      block.label
    }}</b-button>

    <b-modal id="how-to" :title="block.title" ok-only>
      <div v-html="block.text"></div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'Help'
}
</script>
