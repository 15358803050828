<template>
  <div id="mapContainer"></div>
</template>

<script>
import { contextMixin } from '@/mixins/context.js'
export default {
  name: 'Map',
  mixins: [contextMixin],
  props: {
    features: {
      type: [Array, Object],
      required: true,
      default() {
        return ['default', 'yes']
      }
    }
  },
  data() {
    return {
      leafletMap: null,
      loading: true,
      popupOptions: {
        maxWidth: 500,
        maxHeight: 400
      }
    }
  },
  methods: {
    setupLeafletMap() {
      this.leafletMap = L.map('mapContainer').setView([45.259, -117.743], 4)

      var streets = L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          maxZoom: 18,
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetleafletMap</a> contributors'
        }
      ).addTo(this.leafletMap)
      var satellite = L.tileLayer(
        'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}',
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox.satellite',
          accessToken:
            'pk.eyJ1Ijoic29pbGNhcmJvbiIsImEiOiJja2ZjdWZ3YTgwMDhxMnF0N216NTE1OTMyIn0.kI-dBmCGoGBbrahF-sQFgA'
        }
      ).addTo(this.leafletMap)
      var baseMaps = {
        streets: streets,
        satellite: satellite
      }
      L.control.layers(baseMaps).addTo(this.leafletMap)
      L.control
        .scale({ position: 'bottomright', metric: false })
        .addTo(this.leafletMap)
    },
    getLabel(obj) {
      if (obj.json) {
        var labels = ['title', 'caption', 'photodescription']
        var theLabel = ''
        for (var i = 0; i < labels.length; i++) {
          if (obj.json.hasOwnProperty(labels[i])) {
            theLabel = labels[i].slice(0, 50)
          }
        }
        return theLabel ? obj.json[theLabel] : obj.label
      } else {
        return obj.label
      }
    },
    makeList: function (obj) {
      var popup = '<table>'
      if (obj.length) {
        popup += '<tr><td><em>Click to open in new tab:</em></td></tr>'
        for (var i = 0; i < obj.length; i++) {
          popup +=
            '<tr><td><a href="/' +
            this.projectName +
            '/observations/' +
            obj[i].id +
            '/" target="_blank">' +
            this.getLabel(obj[i]) +
            '</a></td></tr>'
        }
        popup += '</table>'
      } else {
        popup = '<p>No observations for this site</p>'
      }
      return popup
    },
    onEachFeature: function (feature, layer) {
      layer
        .bindTooltip(feature.properties.name)
        .bindPopup(
          this.makeList(feature.properties.site_observations),
          this.popupOptions
        )
    },

    addData: function () {
      var myfeatures = L.geoJSON(this.features, {
        onEachFeature: this.onEachFeature
      })
      if (Array.isArray(this.features)) {
        var markers = L.markerClusterGroup()
        markers.addLayer(myfeatures)
        this.leafletMap.addLayer(markers)
      } else {
        myfeatures.addTo(this.leafletMap)
      }
      this.leafletMap.fitBounds(myfeatures.getBounds(), {
        padding: [50, 50]
      })
      this.loading = false
    }
  },

  mounted() {
    this.setupLeafletMap()
    this.addData()
  },
  beforeDestroy() {
    if (this.leafletMap) {
      this.leafletMap.remove()
    }
  }
}
</script>

<style scoped>
#mapContainer {
  width: 80vw;
  height: 100vh;
  z-index: 5;
}
tr,
td {
  padding: 5px;
}
tr:nth-child(even) {
  background-color: #eee;
}
</style>
