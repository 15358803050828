<template>
  <div>
    <!-- FOR COOKIELAW CONFIG SEE https://www.npmjs.com/package/vue-cookie-law -->
    <cookie-law theme="blood-orange">
      <div slot="message">
        This website uses cookies only to improve site performance for signed-in
        users.
        <router-link :to="{ name: 'TermsOfService' }"
          >Terms of Service and Privacy</router-link
        >
      </div>
    </cookie-law>
    <p align="center">
      soilhealth.app is a project of the
      <a href="https://soilcarboncoalition.org" target="_blank"
        >Soil Carbon Coalition</a
      >
      <br />
      <router-link :to="{ name: 'TermsOfService' }"
        >Terms of Service and Privacy</router-link
      >
    </p>
  </div>
</template>
<script>
import CookieLaw from 'vue-cookie-law'
export default {
  components: { CookieLaw }
}
</script>
<style scoped>
div {
  color: white;
  background-color: #343a40;
}
p {
  margin-bottom: 0px;
  padding: 10px;
}
a {
  color: lightgreen;
}
a:hover {
  color: lightblue;
}
</style>
