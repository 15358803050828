<template>
  <div>
    <Loading v-if="loading && delayElapsed" />
    <Error v-if="error" :error="error" />
    <div v-if="posts">
      <b-row>
        <h3 v-if="thisProject">
          <span style="color: blue">{{ filteredPosts.length }}</span>
          posts
        </h3>
        <h3 v-else>
          <span class="alert alert-warning">Error: No project selected.</span>
        </h3>
      </b-row>
      <b-row>
        <p>Enter any search term to filter posts by keyword.</p>

        <div>
          <b-form inline @submit.prevent>
            <b-input
              v-model="search"
              class="m-3"
              placeholder="Search"
              type="search"
            ></b-input>
          </b-form>
        </div>
      </b-row>

      <div>
        <div v-if="filteredPosts.length">
          <div v-for="post in filteredPosts" :key="post.id" class="post">
            <router-link :to="{ name: 'PostDetail', params: { id: post.id } }">
              <h4>{{ post.title }}</h4>
            </router-link>
            <p>posted by {{ post.author_name }} on {{ post.date }}</p>
            <div v-if="post.body">
              {{ sliced(post.body) }} . . .
              <router-link :to="{ name: 'PostDetail', params: { id: post.id } }"
                >read more</router-link
              >
            </div>
          </div>
        </div>
        <div v-else>
          <h2>No posts yet.</h2>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api.js'
import GlobalComponents from '@/globalComponents.js'
export default {
  title: 'Post list',
  name: 'PostList',
  props: ['thisProject'],
  components: { ...GlobalComponents },
  data() {
    return {
      posts: null,
      error: null,
      loading: false,
      delayElapsed: false,
      search: ''
    }
  },
  computed: {
    filteredPosts() {
      return this.posts.filter((post) => {
        return JSON.stringify(post).match(new RegExp(this.search, 'i'))
      })
    }
  },

  methods: {
    sliced(html) {
      if (typeof html == 'string' || html instanceof String) {
        return html.slice(0, 120).replace(/<[^>]*>?/gm, ' ')
      }
    },
    fetchPosts() {
      this.loading = true
      this.posts = this.error = null
      this.delayElapsed = false
      api
        .get(this.thisProject.name + '/posts/')
        .then((response) => {
          this.posts = response.data
        })
        .catch((error) => {
          this.error = error
        })
        .finally(() => {
          this.loading = false
        })
      setTimeout(() => {
        this.delayElapsed = true
      }, 300)
    }
  },

  created() {
    this.fetchPosts()
  }
}
</script>
<style scoped>
.post {
  border: 2px solid rgb(76, 0, 155);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 153, 255, 0.25),
    0 6px 20px 0 rgba(0, 153, 255, 0.25);
  margin: 8px;
  padding: 4px;
}
.post:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 100, 200, 0.7),
    0 6px 20px 0 rgba(0, 100, 200, 0.7);
  margin: 8px;
  padding: 4px;
}
</style>
