<template>
  <div id="mapContainer"></div>
</template>

<script>
// import 'leaflet/dist/leaflet.css'
// import Leaflet from 'leaflet'

export default {
  name: 'FeatureMap',
  props: {
    feature: Object
  },

  data() {
    return {
      leafletMap: null
    }
  },
  methods: {
    setupLeafletMap: function () {
      this.leafletMap = L.map('mapContainer')
      // .setView([45, -117], 7)
      // L.marker(this.latlng).addTo(this.leafletMap)

      var streets = L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          maxZoom: 18,
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetleafletMap</a> contributors'
        }
      ).addTo(this.leafletMap)
      var satellite = L.tileLayer(
        'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}',
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox.satellite',
          accessToken:
            'pk.eyJ1Ijoic29pbGNhcmJvbiIsImEiOiJja2ZjdWZ3YTgwMDhxMnF0N216NTE1OTMyIn0.kI-dBmCGoGBbrahF-sQFgA'
        }
      ).addTo(this.leafletMap)
      var baseMaps = {
        streets: streets,
        satellite: satellite
      }
      L.control.layers(baseMaps).addTo(this.leafletMap)
      L.control
        .scale({ position: 'bottomright', metric: false })
        .addTo(this.leafletMap)
    },
    addFeature() {
      var myfeature = L.geoJSON(this.feature, {}).addTo(this.leafletMap)

      this.leafletMap.fitBounds(myfeature.getBounds(), {
        padding: [125, 125]
      })
    }
  },

  mounted() {
    this.setupLeafletMap()
    this.addFeature()
  },
  beforeDestroy() {
    if (this.leafletMap) {
      this.leafletMap.remove()
    }
  }
}
</script>
<style scoped>
#mapContainer {
  width: 45vw;
  height: 50vh;
}
</style>
