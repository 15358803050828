<template>
  <div>
    <Loading v-if="syncing">Uploading to database ....</Loading>
    <Error v-if="error" :error="error" />
    <h1>Outbox</h1>
    <p>
      Multiple sites and observations can be saved here. If you are online, you
      may upload your data with the "upload data" button below, which may take a
      few seconds to appear. Otherwise, wait till this device has stable network
      connection to upload your data.
    </p>
    <b-button
      variant="info"
      class="m-3"
      size="lg"
      @click="modalShow = !modalShow"
      >Offline tips</b-button
    >
    <h2 v-if="obsLength + siteLength + idObsLength == 0">
      <span style="color: green">Your outbox is empty.</span>
    </h2>
    <h3 v-if="siteLength">
      You have
      <b
        ><span style="color: purple">{{ siteLength }}</span></b
      >
      sites to upload to database. Be sure to add at least one observation
      before uploading.
    </h3>
    <h3 v-else>No sites to upload</h3>
    <div v-for="(item, index) in siteOutbox" :key="index" class="siteItem">
      <h4>{{ item[1].name }}</h4>
      <p>{{ item[1].geometry.type }}: {{ item[1].geometry.coordinates }}</p>
      <b-button
        v-if="status"
        class="mt-4"
        variant="primary"
        @click="addObs(item[1])"
      >
        <b-icon-plus-circle />&nbsp;Add observation to this site
      </b-button>
      <!-- can only delete site if no obs attached -->
      <div v-if="!obsLength">
        <b-button @click.once="siteDelete(index)" variant="danger"
          >Delete this site</b-button
        >
      </div>
    </div>
    <h3 v-if="obsLength">
      You have
      <b
        ><span style="color: blue">{{ obsLength }}</span></b
      >
      observations on the above site(s) to upload to database
    </h3>
    <div v-for="(item, index) in obsOutbox" :key="index" class="obsItem">
      <div v-html="formatObsTable(item[1])"></div>
      <div>
        <b-button @click.once="obsDelete(index)" variant="danger"
          >Delete this observation</b-button
        >
      </div>
    </div>
    <h3 v-if="idObsLength">
      You have
      <b
        ><span style="color: green">{{ idObsLength }}</span></b
      >
      observations on other sites to upload to database
    </h3>
    <div v-for="(item, index) in idObsOutbox" :key="index" class="obsItem">
      <div v-html="formatObsTable(item[1])"></div>
      <div>
        <b-button @click.once="idObsDelete(index)" variant="danger"
          >Delete this observation</b-button
        >
      </div>
    </div>
    <Error v-if="error" :error="error" />
    <b-button
      v-if="outbox && online"
      @click="syncDb"
      variant="dark"
      size="lg"
      class="m-5 w-75"
    >
      <b-spinner small v-if="syncing"></b-spinner>&nbsp;&nbsp;Upload data
    </b-button>
    <h3 v-if="outbox && !online">
      You can upload items when your device has network connection
    </h3>
    <b-modal v-model="modalShow" ok-only>
      <template v-slot:modal-title>Offline tips</template>
      <template v-slot:default>
        <p>
          While offline without cell service, you should be able to record Sites
          and Observations for upload later when you are online (from the same
          smartphone or computer that you used to record these observations).
          Your observations, including photos, will be saved to this Outbox. To
          use soilhealth.app offline, be sure to follow these 2 steps with the
          device you plan to use offline:
        </p>
        <ol>
          <li>
            Make sure you have logged in while online within the last 14 days
            with this device. (It is not possible to log in while offline.)
          </li>
          <li>
            Choose "Add to Home screen" options for iPhone or Android devices.
            This will enable you to open the web app while offline. Without this
            step, you will still be able to use the web app offline
            <b>provided that you open the app while online.</b>
          </li>
        </ol>
        <p>
          If your network connection is spotty or intermittent, it may be better
          to use Airplane mode while entering sites and observations.
        </p>
        <p>
          While entering sites and observations on your mobile device may be a
          convenient way to capture location coordinates, photos, and text or
          numbers, there is nothing wrong with using paper and pencil! You can
          enter these observations later into the soilhealth.app database.
        </p>
      </template>
    </b-modal>
  </div>
</template>

<script>
import api from '@/api.js'
import GlobalComponents from '@/globalComponents.js'
import { contextMixin } from '@/mixins/context.js'
import localForageMixin from '@/mixins/localForageMixin.js'
import onlineMixin from '@/mixins/onlineMixin.js'

export default {
  title: 'Outbox',
  name: 'Outbox',
  mixins: [contextMixin, localForageMixin, onlineMixin],
  components: {
    ...GlobalComponents
  },
  data() {
    return {
      error: null,
      syncing: false,
      modalShow: false
    }
  },
  methods: {
    editObs(obs) {
      // opens ObsEdit with obs object (as prop?)
    },
    addObs(site) {
      localStorage.setItem('site', JSON.stringify(site))
      this.$router.push({ name: 'ProjectQuestions' })
    },
    siteDelete(index) {
      confirm('Are you sure you want to delete this site?')
      this.siteTable.removeItem(this.siteOutbox[index][0])
      this.siteOutbox.splice(index, 1) // 2nd parameter means remove one item only
      this.siteLength -= 1
      localStorage.removeItem('site')
    },
    obsDelete(index) {
      confirm('Are you sure you want to delete this observation?')
      this.obsTable.removeItem(this.obsOutbox[index][0])
      this.obsOutbox.splice(index, 1) // 2nd parameter means remove one item only
      this.obsLength -= 1
    },
    idObsDelete(index) {
      confirm('Are you sure you want to delete this observation?')
      this.idObsTable.removeItem(this.idObsOutbox[index][0])
      this.idObsOutbox.splice(index, 1) // 2nd parameter means remove one item only
      this.idObsLength -= 1
    },
    formatObsTable(obj) {
      let t = '<table>'
      t +=
        '<tr><td colspan="2" class="head"><b>' +
        obj.questionName +
        ' on <em>' +
        obj.siteName +
        '</em></span></b></td></tr>'
      for (const [k, v] of Object.entries(obj)) {
        // don't display these
        const skips = [
          'project',
          'observer',
          'question',
          'questionName',
          'siteName'
        ]
        if (!skips.includes(k)) {
          t += '<tr><td><b>' + k + ': </b></td>'
          if (k == 'image') {
            //THIS NEEDS TO BE MORE ROBUST
            t +=
              '<td><img src="' +
              URL.createObjectURL(v) +
              '" width="300px" / ></td></tr>'
            URL.revokeObjectURL(v)
          } else {
            t += '<td>' + v + '</td></tr>'
          }
        }
      }
      t += '</table>'
      return t
    },
    async postOneObs(obs, obkey) {
      delete obs.questionName //only needed for Outbox display
      const siteName = obs.siteName
      delete obs.siteName //only needed for Outbox display
      const fd = new FormData()
      for (const [k, v] of Object.entries(obs)) {
        fd.append(k, v)
      }
      const response = await api.post('/obs-new/', fd)
      if (response.status === 201) {
        if (obkey.startsWith(siteName)) {
          this.obsTable.removeItem(obkey)
          this.obsLength -= 1
        } else {
          this.idObsTable.removeItem(obkey)
          this.idObsLength -= 1
        }
      } else {
        alert('Error in posting ' + JSON.stringify(obs))
      }
    },
    async syncDb() {
      this.syncing = true
      if (this.siteOutbox.length) {
        // post sites in Outbox
        try {
          for (var i = 0; i < this.siteOutbox.length; i++) {
            const site = this.siteOutbox[i][1]
            const siteKey = this.siteOutbox[i][0]
            const response = await api.post('sites/new', site)
            site.id = response.data.id
            await this.postSiteObs(site) // async, posts all obs matching site name
            localStorage.setItem('site', JSON.stringify(site)) //updates localStorage with site.id
            if (response.status === 201) {
              await this.siteTable.removeItem(siteKey)
            } else {
              alert('ERROR posting site')
            }
          }
        } catch (e) {
          this.error = e
        }
      }
      await this.postObs() // posts obs that are keyed to existing sites
      this.syncing = false
      // goes to Observation map page if successful
      if (!this.error && this.online) {
        this.$router.push('/' + this.projectName + '/observations/')
      }
    },
    async postSiteObs(site) {
      for (var ob = 0; ob < this.obsOutbox.length; ob++) {
        try {
          const obsKey = this.obsOutbox[ob][0]
          const obs = this.obsOutbox[ob][1]
          // if obs belongs to site, give site.id and post
          if (obsKey.split('_')[0] == site.name) {
            obs.site = site.id
            await this.postOneObs(obs, obsKey)
          }
        } catch (e) {
          this.error += e.message
        }
      }
      return
    },
    async postObs() {
      // this posts observations that are already keyed to existing sites
      if (this.idObsOutbox.length) {
        for (var m = 0; m < this.idObsOutbox.length; m++) {
          try {
            const obsKey = this.idObsOutbox[m][0]
            const obs = this.idObsOutbox[m][1]
            await this.postOneObs(obs, obsKey)
          } catch (e) {
            this.error = e
          }
        }
        return
      }
    }
  },
  created() {
    if (localStorage.site) {
      this.site = JSON.parse(localStorage.site)
    }
  }
}
</script>
<style scoped>
.obsItem {
  border: 2px solid rgb(12, 15, 206);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 153, 255, 0.25),
    0 6px 20px 0 rgba(0, 153, 255, 0.25);
  margin: 8px;
  padding: 4px;
}
.obsItem >>> td {
  padding: 4px;
}
.obsItem >>> td.head {
  padding: 4px;
  color: blue;
  font-size: 1.7em;
}
.obsItem >>> tr:nth-child(even) {
  background-color: #eee;
}
.siteItem {
  border: 2px solid rgb(47, 10, 79);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 153, 255, 0.25),
    0 6px 20px 0 rgba(0, 153, 255, 0.25);
  margin: 8px;
  padding: 4px;
}
</style>
