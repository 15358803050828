<template>
  <b-container>
    <h2>Quickstart guide</h2>
    <p>
      This is a web app, usable on mobile devices or desktop computers with a
      web browser, 1) for entering and displaying geolocated observations and
      data, and 2) for posing questions and making observations about how
      landscapes and economies function, typically at a local level. The Menu is
      at upper right. For purpose and design, see the
      <a href="/about/">About</a> page.
    </p>
    <p>
      To enter observations and data, you must be part of a
      <a href="/projects/" rel="noopener noreferrer" target="_blank">Project</a
      >. This app is not usable for data entry unless you are a member of a
      project.
    </p>
    <p>
      If you are interested in starting a project, contact Peter Donovan at
      managingwholes.com@gmail.com. If you would like to participate in a
      project, contact the project coordinator. List of active projects
      <a href="/projects/" rel="noopener noreferrer" target="_blank">here</a>.
    </p>
    <h2>Entering observations or data</h2>
    <p>
      <b>Mobile device or desktop?</b> soilhealth.app is a web app, usable on
      any device with a web browser such as a smartphone or desktop computer
      with internet access. You do <b>not</b> need to download this from Google
      Play or from Apple's App Store. For use at home, you can log into
      soilhealth.app with a desktop or laptop computer. For field use, you may
      log in with a smartphone or GPS-equipped tablet, which can also function
      offline, without cell signal or internet connectivity (see Offline use
      section below). For field use, there is <b>nothing wrong</b> with
      recording observations with pencil and paper and entering them
      later--although it is often convenient to record coordinates of your
      observation site with soilhealth.app or other device.
    </p>
    <p>
      Every Observation must be tied to a Site (usually a point, but can be a
      polygon). So, to begin, you might add a Site using your device's GPS, or
      enter coordinates, enter a site name or identifier. Then you can add an
      Observation to that site, choosing from a list of Questions (types of
      observation) such as Image with caption, Evidence of soil health, or
      Comment. Most of these Questions or data types can be used flexibly. An
      Image, for example, can be a chart or a photo showing anything from the
      soil surface to the results of a soil health demonstration such as
      rainfall simulator or slake test or other experiment. Some are designed
      for specific types of data entry, such as Infiltration with its multiple
      recording stopwatches for timing multiple infiltrations of water to the
      soil surface. If your project wants other Questions or data types, they
      can be added by the Project Coordinators, with any combination of photos,
      text, or numbers.
    </p>
    <p>
      Observations are part of a Project, typically a locally driven and
      coordinated effort toward a better understanding of local landscape
      function. Observations, Posts, and Maps can be flagged as public (anyone
      can view) or private (project participants only).
    </p>
    <p>
      Observations, when you click the Submit button, go to your Outbox page. If
      you think you've made an error they can be deleted from your Outbox. When
      you have an internet connection, you should upload your Sites and
      Observations. You can log in and use soilhealth.app from multiple devices
      or computers, but Sites and Observations stored in the Outbox are stored
      on the device on which you recorded them.
    </p>
    <h2>Offline use</h2>
    <p>
      Users who have logged in within the past 14 days can use this app offline
      without a network connection, but such use is limited to recording Sites
      and Observations, which can be uploaded from your Outbox when your device
      has stable network connection. To use soilhealth.app offline, be sure to
      follow these 2 steps with the device you plan to use offline:
    </p>
    <ol>
      <li>
        Make sure you have logged in while online within the last 14 days with
        this device. (It is not possible to log in while offline.)
      </li>
      <li>
        Choose "Add to Home screen" options for iPhone or Android devices. This
        will enable you to open the web app while offline. Without this step,
        you will still be able to use the web app offline
        <b>provided that you open the app while online.</b>
      </li>
    </ol>
    <p>
      While entering sites and observations on your mobile device may be a
      convenient way to capture location coordinates, photos, and text or
      numbers, there is nothing wrong with using paper and pencil! You can enter
      these observations later into the soilhealth.app database.
    </p>
    <p>
      Project participants can also add Posts, a combination of text and
      pictures that are not tied to a particular geolocated site. Video can be
      embedded.
    </p>

    <p>
      See also <a href="/terms-of-service#roles">Roles and Responsibilities</a>
    </p>

    <h2>Common difficulties</h2>
    <p>
      This web app will store some data on your device. If this data is outdated
      or corrupt, it may help to clear the soilhealth.app data from your
      browser. Logging out and then logging back in may help with some errors.
      <b
        >Be sure to upload any observations you have already recorded
        beforehand.</b
      >
      Instructions for most browsers for clearing stored data:
      <a
        href="https://www.minitool.com/news/clear-cache-for-one-site.html"
        target="_blank"
        >here</a
      >. After this you will need to log in again.
    </p>

    <h2>Project coordinator role</h2>
    <p>
      The project coordinator role is fundamental, coordinating the web of
      relationships that the project consists of, toward a shared intelligence
      around the large forces and functions such as carbon cycling, water
      cycling, diversity and biodiversity, and local economics.
    </p>
    <p>
      There can be more than one project coordinator. Project coordinators have
      administrator permissions on soilhealth.app.
    </p>
    <p>Project coordinators can:</p>
    <ul>
      <li>
        register and admit users as Observers into their project, where they can
        view and post Observations and Posts.
      </li>
      <li>curate or moderate observations and posts</li>
      <li>
        find, format, and post existing or legacy data that is relevant to their
        project
      </li>
      <li>
        formulate new questions for their project with data forms to match
      </li>
      <li>
        engage people in data collecting, presentation, interpretation and
        dialogue
      </li>
      <li>
        engage schools, citizens, professionals, government and nonprofit
        people, media, and businesses in the above
      </li>
      <li>
        report actual results (for example, in the function of water or carbon
        cycling, diversity, economic resilience) to project funders and
        stakeholders
      </li>
      <li>
        Ask, what relationships are fundamental to the success of this
        community, and how might we recognize them and learn about them?
      </li>
    </ul>
    <p>
      Project coordinators can be volunteer, or paid (for example by local
      agencies or funders). The Soil Carbon Coalition does not expect to pay
      project coordinators, but to support their work via soilhealth.app and
      workshop sessions.
    </p>
    <p>
      See also: <a href="/terms-of-service#roles">Roles and Responsibilities</a>
    </p>
    <h2>Custom maps (project coordinators only)</h2>
    <p>
      Custom maps can combine multiple sources of data on one map, including
      data that is not in soilhealth.app's database. Custom or mashup maps can
      combine raster layers (tilesets, base maps, or background maps), as well
      as vector or data layers consisting of points, lines, or polygons with
      attributes or properties. Maps may be configured through the
      soilhealth.app administration interface, only by project coordinators.
    </p>
    <p>
      Maps may be configured with center latitude and longitude and zoom level.
      Then raster layers or tilesets may be entered: Note: the Open Street Map
      layer is added first for all maps. Satellite maps and other rasters
      tilesets can be added.
    </p>
    <h3>Vector data</h3>
    <p>
      Sources of data may include APIs, including queries to soilhealth.app's
      database through its API, or external websites that provide data through
      APIs. Sources of data may also include files that are uploaded to
      soilhealth.app's server, such as geojson, csv, or kml files, and which
      have columns or fields for coordinates. Shapefiles can also be included,
      however it is best to convert them to geojson first (several websites can
      do this free).
    </p>
    <h2>A note on the API</h2>
    <p>
      soilhealth.app data is also accessible via our application programming
      interface. It responds to requests in JSON format. For observations,
      posts, and maps, the API will return only public data, unless you are
      credentialed to a project in soilhealth.app. Examples:
    </p>
    <ol>
      <li>
        <a href="https://soilhealth.app/api/RMG-Montana/observations"
          >https://soilhealth.app/api/RMG-Montana/observations</a
        >
        shows the 10 latest observations from the RMG-Montana project
      </li>
      <li>
        <a
          href="https://soilhealth.app/api/RMG-Montana/observations/?page_size=100&json__icontains=alfalfa"
          >https://soilhealth.app/api/RMG-Montana/observations/?page_size=100&json__icontains=alfalfa</a
        >
        shows up to 100 observations containing the string "alfalfa"
      </li>
    </ol>
  </b-container>
</template>
<script>
export default {
  title: 'Help',
  name: 'Help'
}
</script>
