<template>
  <div>{{ block.text }}</div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'Note'
}
</script>
<style scoped>
div {
  font-style: italic;
  color: rgb(1, 1, 82);
  font-size: 1.2rem;
  background-color: rgb(250, 250, 230);
  border: 2px solid black;
  border-radius: 0.5rem;
  margin-bottom: 4px;
  padding: 8px;
}
</style>
