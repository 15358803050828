<template>
  <b-container>
    <Loading v-if="loading && delayElapsed" />
    <Error v-if="error" :error="error" />
    <div v-if="thisProject">
      <router-view :thisProject="thisProject" :key="$route.path" />
    </div>
  </b-container>
</template>
<script>
import api from '@/api.js'
import GlobalComponents from '@/globalComponents.js'
import { contextMixin } from '@/mixins/context.js'
import localForageMixin from '@/mixins/localForageMixin.js'
export default {
  title: 'soilhealth.app',
  name: 'ProjectHome',
  props: ['name'],
  mixins: [contextMixin, localForageMixin],
  components: { ...GlobalComponents },
  data() {
    return {
      loading: false,
      error: null,
      delayElapsed: false,
      thisProject: null
    }
  },
  methods: {
    async loadProject(name) {
      this.thisProject = this.error = null
      this.loading = true
      this.delayElapsed = false
      try {
        const response = await api.get(name)
        this.thisProject = response.data
        this.online = true
      } catch (e) {
        this.online = false
      }
    }
  },
  created() {
    this.loadProject(this.name)
  }
}
</script>
<style scoped></style>
