<template>
  <!-- THIS COMPONENT ADDS SUBCOMPONENTS FOR FORM FIELDS ACCORDING TO THE JSON IN THE QUESTION
INSTANCE, INCLUDING A SUBCOMPONENT THAT HAS BUTTON FOR ADDING ADDITIONAL FORM FIELDS 
  SUCH AS INFILTRATION timers, SOIL ANALYSES, ETC-->
  <b-container>
    <Error v-if="error" :error="error" />
    <div v-if="question">
      <h3>
        {{ question.name
        }}<span v-if="this.site">
          on site
          <span class="siteName"
            ><em>{{ this.site.name }}</em></span
          ></span
        >
      </h3>
      <p v-if="!this.status" class="warning">
        Note: Only project participants may submit data using this form.
      </p>
      <p v-if="!this.site" class="warning">
        NO SITE SELECTED. This form requires a site to attach the observation
        to. Choose 'Add new site' from the Menu at upper right, or select
        'Observations,' choose an observation from the site you want, and press
        the 'Add new observation' button at the bottom.
      </p>
      <div v-if="question.whyandhow">
        <b-button class="w-50 m-4" size="lg" v-b-modal.how-to variant="info"
          >Why and how?</b-button
        >

        <b-modal id="how-to" :title="question.name" ok-only>
          <div v-html="question.whyandhow"></div>
        </b-modal>
      </div>

      <b-modal
        id="missing"
        variant="warning"
        title="These fields are required:"
        ok-only
      >
        <p
          class="missingItem"
          v-for="(item, index) in this.missing"
          :key="index"
        >
          {{ item }}
        </p>
      </b-modal>

      <form @submit.prevent="submit">
        <template v-for="block in question.json">
          <!-- LISTENS FOR 'add-form' emitted by child component -->
          <component
            :is="block.component"
            :block="block"
            :key="block._uid"
            ref="formfield"
            @add-form="addForm"
          ></component>
        </template>

        <b-form-checkbox size="lg" v-model="privat" class="mb-2"
          >Uncheck to make this observation public</b-form-checkbox
        >
        <div class="confirm">{{ question.confirm }}</div>

        <b-button
          v-if="this.status && this.site"
          @click="onSubmit"
          variant="dark"
          size="lg"
          class="m-5 w-75"
        >
          <b-spinner small v-if="submitting"></b-spinner>&nbsp;&nbsp;Submit data
        </b-button>
      </form>
      <Error v-if="error" :error="error" />
    </div>
    <div v-else>
      <p class="warning">
        Oops! No Question selected. Please choose one of
        <a href="questions">these.</a>
      </p>
    </div>
    <b-modal
      id="leaveModal"
      ref="leaveModal"
      centered
      variant="warning"
      size="lg"
      title="Are you sure you want to leave?"
      ok-title="leave"
      ok-variant="secondary"
      cancel-title="stay"
      cancel-variant="primary"
      button-size="lg"
    >
      <h2 class="missingItem">You may have unsaved data!</h2>
    </b-modal>
  </b-container>
</template>

<script>
import FormfieldComponents from '@/formfieldComponents.js'
import GlobalComponents from '@/globalComponents.js'
import { contextMixin } from '@/mixins/context.js'
import localForageMixin from '@/mixins/localForageMixin.js'

export default {
  title: 'New observation',
  name: 'ObsNew',
  mixins: [contextMixin, localForageMixin],
  components: {
    ...FormfieldComponents,
    ...GlobalComponents
  },
  props: ['question'],
  // this component gets site from contextMixin
  data() {
    return {
      privat: true,
      submitting: false,
      editing: false,
      missing: [],
      loading: false,
      error: null,
      delayElapsed: false
      // siteLength, siteTable, obsLength, obsTable, obsKey, siteKey are available from localforage Mixin
    }
  },
  methods: {
    onFileSelected(event) {
      this.url = URL.createObjectURL(event.target.files[0])
      URL.revokeObjectURL(event.target.files[0])
    },
    addForm(component) {
      // this constitutes mutating a prop
      this.question.json.push(component)
    },
    showLeaveModal() {
      this.$refs.leaveModal.show()
    },
    onSubmit(evt) {
      /* creates an Object from the input
       then puts it into localforage (part of IndexDB), 
       in obsTable if it has name as site id, in
       idObsTable if it has a site ID
      */
      evt.preventDefault()
      // VALIDATION of required fields
      this.missing = []
      for (var i = 0; i < this.$refs.formfield.length; i++) {
        if (
          (this.$refs.formfield[i].fieldvalue < 0 ||
            this.$refs.formfield[i].fieldvalue == '') &&
          this.$refs.formfield[i].block.required
        ) {
          this.missing.push(this.$refs.formfield[i].block.label)
        }
      }
      if (this.missing.length) {
        this.$bvModal.show('missing')
        return
      }
      this.submitting = true // spinner activated

      const myObs = {} // the object containing the input
      // add base fields
      myObs.project = this.projectID
      myObs.question = this.question.id
      myObs.observer = this.authObserver.my_id
      myObs.private = this.privat
      myObs.questionName = this.question.name // only for Outbox display
      myObs.siteName = this.site.name //only for Outbox display
      // adds data for JSON field
      for (var i = 0; i < this.$refs.formfield.length; i++) {
        if (
          this.$refs.formfield[i].block.name &&
          this.$refs.formfield[i].fieldvalue
        ) {
          myObs[this.$refs.formfield[i].block.name] =
            this.$refs.formfield[i].fieldvalue
        } else if (this.$refs.formfield[i].fieldvalue) {
          myObs[this.$refs.formfield[i].namevalue] =
            this.$refs.formfield[i].fieldvalue
        }
      }
      // get probably unique key: last 5 digits of time now in seconds
      const seconds = String(Math.floor(new Date().getTime() / 1000)).slice(-5)
      // if it has site ID, put in idObsTable
      if ((myObs.site = this.site.id)) {
        this.idObsTable
          .setItem(myObs.site + '_' + seconds, myObs)
          .then(() => {
            this.$router.push('/' + this.projectName + '/outbox/')
          })
          .catch((error) => {
            this.submitting = false
            this.error = error
          })
      } else {
        // if it doesn't have site id, give it the site name and put in obsTable
        myObs.site = this.site.name
        this.obsTable
          .setItem(myObs.site + '_' + seconds, myObs)
          .then(() => {
            this.$router.push('/' + this.projectName + '/outbox/')
          })
          .catch((error) => {
            this.submitting = false
            this.error = error
          })
      }
    }
  },
  created() {
    if (localStorage.question) {
      // this constitutes mutating a prop
      this.question = JSON.parse(localStorage.question)
    }
  },
  beforeRouteLeave(to, from, next) {
    // shows modal for possibly unsaved data
    if (this.status && !this.submitting) {
      this.showLeaveModal()
      this.$nextTick(() => {
        // Set up hide handler.
        // Done in next tick to ensure Modal is in DOM
        this.$refs.leaveModal.$once('hide', (bvEvt) => {
          if (bvEvt.trigger === 'ok') {
            // User clicked on built in OK button
            // So we set up a handler to listen for modal 'hidden' event
            // and advance to next route once hidden
            this.$refs.leaveModal.$once('hidden', () => {
              next()
            })
          } else {
            // User clicked cancel or the modal 'x' close button
            next(false)
          }
        })
      })
    } else {
      next()
    }
  }
}
</script>

<style scoped>
.hint {
  font-size: 1.4em;
}
.missingItem {
  border: 2px;
  border-style: solid;
  border-color: #ff0000;
  padding: 1em;
  border-radius: 0.5rem;
  background-color: cornsilk;
  color: blue;
}
.confirm {
  font-style: bold, italic;
  color: #000;
  font-size: 1.4rem;
  background-color: rgb(255, 250, 230);
  border: 2px solid black;
  border-radius: 0.5rem;
  margin-bottom: 4px;
  padding: 0.5rem;
}
.siteName {
  color: blue;
}
p.warning {
  color: rgb(138, 4, 11);
  font-size: 1.4rem;
  font-style: italic;
}
</style>
