<template>
  <div>
    <h1>{{ map.name }}</h1>
    <b-tabs content-class="mt-3">
      <b-tab title="Map" active>
        <div id="mapContainer"></div>
      </b-tab>
      <b-tab
        v-for="item in tabArr"
        :key="item.title"
        :title="item.title"
        v-html="item.text"
      ></b-tab>
    </b-tabs>
  </div>
</template>

<script>
import api from '@/api.js'
import omnivore from '@mapbox/leaflet-omnivore'
import { contextMixin } from '@/mixins/context.js'
export default {
  title: 'Map instance',
  name: 'MapDetail',
  mixins: contextMixin,
  props: ['id'],
  data() {
    return {
      // NOTE 'map' is data object from api, 'leafletMap' is the map Leaflet creates
      map: Object,
      leafletMap: null,
      mediaURL: 'https://soilhealth.app/m/',
      baseLayers: {},
      overLayers: {},
      loading: true,
      tabArr: [], // array of titles, texts for tabs
      popupOptions: {
        maxWidth: 500,
        maxHeight: 400,
        midWidth: 350
      },
      icons: [
        '',
        '-red',
        '-green',
        '-violet',
        '-orange',
        '-grey',
        '-yellow',
        '-black',
        '',
        '-red',
        '-green',
        '-violet',
        '-orange',
        '-grey',
        '-yellow',
        '-black'
      ]
    }
  },
  methods: {
    separateH1(desc) {
      var textArr = desc.split('<h1>')
      if (textArr.length < 2) {
        return desc
      } else if (textArr.length > 1) {
        textArr.shift() //discard text before first h1
        for (var i = 0; i < textArr.length; i++) {
          var mytitle = textArr[i].split('</h1>')[0]
          var mytext = '<h1>' + textArr[i]
          this.tabArr.push({ title: mytitle, text: mytext })
        }
      }
    },
    getLabel(obj) {
      if (obj.json) {
        var labels = ['title', 'caption', 'photodescription']
        var theLabel = ''
        for (var i = 0; i < labels.length; i++) {
          if (obj.json.hasOwnProperty(labels[i])) {
            theLabel = labels[i].slice(0, 30)
          }
        }
        return theLabel ? obj.json[theLabel] : obj.label
      } else {
        return obj.label
      }
    },
    makeList: function (obj) {
      var popup = '<table>'
      if (obj.length) {
        popup += '<tr><td><em>Click to open in new tab:</em></td></tr>'
        for (var i = 0; i < obj.length; i++) {
          popup +=
            '<tr><td><a href="../observations/' +
            obj[i].id +
            '/" target="_blank">' +
            this.getLabel(obj[i]) +
            '</a></td></tr>'
        }
        popup += '</table>'
      } else {
        popup = '<p>No observations for this site</p>'
      }
      return popup
    },
    loadRasters() {
      // add OSM first
      var osmLayer = new L.TileLayer(
        'http://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
      )
      this.leafletMap.addLayer(osmLayer)
      this.baseLayers['Open Street Map'] = osmLayer
      var satellite = L.tileLayer(
        'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}',
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox.satellite',
          accessToken:
            'pk.eyJ1Ijoic29pbGNhcmJvbiIsImEiOiJja2ZjdWZ3YTgwMDhxMnF0N216NTE1OTMyIn0.kI-dBmCGoGBbrahF-sQFgA'
        }
      )
      this.leafletMap.addLayer(satellite)
      this.baseLayers['satellite'] = satellite
      var rasterNames = []
      if (this.map.rasters) {
        for (var i = 0; i < this.map.rasters.length; i++) {
          rasterNames.push(this.map.rasters[i].name)
          this.map.rasters[i].name = L.tileLayer(this.map.rasters[i].tileUrl, {
            attribution: this.map.rasters[i].attrs.attribution
          })
          this.baseLayers[rasterNames[i]] = this.map.rasters[i].name
        }
      }
    },
    handleCsv(obj) {
      const myIcon = L.icon(obj.icon)
      let styleLayer = L.geoJSON(null, {
        pointToLayer: function (feature, latlng) {
          return L.marker(latlng, {
            icon: myIcon
          })
        },
        onEachFeature: function (feature, layer) {
          if (feature.properties) {
            layer
              .bindTooltip(feature.properties.name)
              .bindPopup(JSON.stringify(feature.properties), this.popupOptions)
          }
        }
      }).addTo(this.leafletMap)
      omnivore.csv(obj.dataUrl, null, styleLayer)
      const name =
        '&nbsp;<img src="' +
        obj.icon.iconUrl +
        '" width="17"> <b>' +
        obj.name +
        '</b>'
      this.overLayers[name] = styleLayer
    },
    handleKml(obj) {
      const myIcon = L.icon(obj.icon)
      let styleLayer = L.geoJSON(null, {
        pointToLayer: function (feature, latlng) {
          return L.marker(latlng, {
            icon: myIcon
          })
        },
        onEachFeature: function (feature, layer) {
          if (feature.properties) {
            layer
              .bindTooltip(feature.properties.name)
              .bindPopup(JSON.stringify(feature.properties), this.popupOptions)
          }
        }
      }).addTo(this.leafletMap)
      omnivore.kml(obj.dataUrl, null, styleLayer)
      const name =
        '&nbsp;<img src="' +
        obj.icon.iconUrl +
        '" width="17"> <b>' +
        obj.name +
        '</b>'
      this.overLayers[name] = styleLayer
    },
    handleGeojson(obj) {
      const myIcon = L.icon(obj.icon)
      let styleLayer = L.geoJSON(null, {
        pointToLayer: function (feature, latlng) {
          return L.marker(latlng, {
            icon: myIcon
          })
        },
        onEachFeature: function (feature, layer) {
          if (feature.properties) {
            layer
              .bindTooltip(feature.properties.name)
              .bindPopup(JSON.stringify(feature.properties), this.popupOptions)
          }
        }
      }).addTo(this.leafletMap)
      omnivore.geojson(obj.dataUrl, null, styleLayer)
      const name =
        '&nbsp;<img src="' +
        obj.icon.iconUrl +
        '" width="17"> <b>' +
        obj.name +
        '</b>'
      this.overLayers[name] = styleLayer
    },
    onEachFeature: function (feature, layer) {
      layer
        .bindTooltip(
          feature.properties.name
            ? feature.properties.name
            : feature.properties.sitename
        )
        .bindPopup(
          this.makeList(feature.properties.site_observations),
          this.popupOptions
        )
    },

    handleDb(obj) {
      const myIcon = L.icon(obj.icon)
      let styleLayer = L.geoJSON(null, {
        pointToLayer: function (feature, latlng) {
          return L.marker(latlng, {
            icon: myIcon
          })
        },
        onEachFeature: this.onEachFeature
      }).addTo(this.leafletMap)
      omnivore.geojson(obj.dataUrl, null, styleLayer)
      const name =
        '&nbsp;<img src="' +
        obj.icon.iconUrl +
        '" width="17"> <b>' +
        obj.name +
        '</b>'
      this.overLayers[name] = styleLayer
    },
    loadVectors() {
      if (this.map.vectors) {
        for (let i = 0; i < this.map.vectors.length; i++) {
          switch (this.map.vectors[i].fileType) {
            case 'csv':
              this.handleCsv(this.map.vectors[i])
              break
            case 'kml':
              this.handleKml(this.map.vectors[i])
              break
            case 'geojson':
              this.handleGeojson(this.map.vectors[i])
              break
            case 'db':
              this.handleDb(this.map.vectors[i])
              break
          }
        }
      }
      L.control
        .layers(this.baseLayers, this.overLayers, { collapsed: false })
        .addTo(this.leafletMap)
      L.control
        .scale({ position: 'bottomright', metric: false })
        .addTo(this.leafletMap)

      this.separateH1(this.map.description)
    },

    initMap() {
      api.get(this.projectName + '/maps/' + this.id).then((response) => {
        this.map = response.data
        this.leafletMap = L.map('mapContainer', {
          center: this.map.center,
          zoom: this.map.zoom
        })
        this.loadRasters()
        this.loadVectors()
      })
    }
  },
  mounted() {
    this.initMap()
    this.loading = false
  },

  beforeDestroy() {
    if (this.leafletMap) {
      this.leafletMap.remove()
    }
  }
}
</script>

<style scoped>
#mapContainer {
  width: 100%;
  height: 100vh;
  z-index: 5;
}

tr:nth-child(even) {
  background-color: #ddd;
}

.mapDetail {
  border-top: 2px solid blue;
}
</style>
