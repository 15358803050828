<template>
  <b-container v-if="!loading">
    <h3>
      Site name:
      <span class="sitename">{{ feature.properties.name }}</span>
      ; Site ID: {{ feature.id }}
    </h3>
    <p>{{ feature.geometry }}</p>
    <hr />
    <FeatureMap :feature="feature"></FeatureMap>
  </b-container>
</template>
<script>
import FeatureMap from '@/components/FeatureMap.vue'
import { contextMixin } from '@/mixins/context.js'
export default {
  name: 'SiteDetail',
  components: {
    FeatureMap
  },
  mixins: [contextMixin],
  props: ['id'],
  data() {
    return {
      feature: {},
      loading: true,
      editing: false
    }
  },

  created() {
    SHService.getSite(this.id)
      .then((response) => {
        this.feature = response.data
        this.loading = false
      })
      .catch((error) => {
        console.log('SiteDetail error: ', error.response)
        this.loading = false
      })
  }
}
</script>
<style scoped>
.sitename {
  color: blue;
}
</style>
