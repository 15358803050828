export default {
  data() {
    return {
      online: true,
      outbox: false
    }
  },
  methods: {
    async checkOnlineStatus() {
      try {
        const online = await fetch('/m/i/pixelx.png')
        return online.status >= 200 && online.status < 500
      } catch (err) {
        return false
      }
    }
  },
  created() {
    this.online = true
    setInterval(async () => {
      const result = await this.checkOnlineStatus()
      // if (result === false) {
      //   this.online === false
      // }
      this.online = result ? true : false
      console.log('online: ', this.online)
      const idObs = await this.idObsTable.length()
      const obs = await this.obsTable.length()
      this.outbox = idObs || obs ? true : false
    }, 10000)
  }
}
