import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
import Home from '@/views/Home.vue'
import About from '@/views/About.vue'
import Help from '@/views/Help.vue'
import ProjectList from '@/views/ProjectList.vue'
// project components
import ProjectHome from '@/views/project/ProjectHome.vue'
import ProjectSummary from '@/views/project/Summary.vue'
import ProjectQuestionList from '@/views/project/QuestionList.vue'
import ProjectObsNew from '@/views/project/ObsNew.vue'
import ProjectObsDetail from '@/views/project/ObsDetail.vue'
import ProjectObsMap from '@/views/project/ObsMap.vue'
import ProjectOutbox from '@/views/project/Outbox.vue'
import ProjectPostList from '@/views/project/PostList.vue'
import ProjectPostDetail from '@/views/project/PostDetail.vue'
import ProjectLocate from '@/views/project/Locate.vue'
import ProjectPostNew from '@/views/project/PostNew.vue'
import ProjectMapList from '@/views/project/MapList.vue'
import ProjectMapDetail from '@/views/project/MapDetail.vue'

import NotFound from '@/views/NotFound.vue'
import NetworkIssue from '@/views/NetworkIssue.vue'
import ServerError from '@/views/ServerError.vue'
import Unauthorized from '@/views/Unauthorized.vue'
import TermsOfService from '@/views/TermsOfService.vue'
import SiteDetail from '@/views/SiteDetail.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about/',
    name: 'about',
    component: About
  },
  {
    path: '/help/',
    name: 'help',
    component: Help
  },
  {
    path: '/terms-of-service/',
    name: 'TermsOfService',
    component: TermsOfService
  },
  {
    path: '/projects/',
    name: 'projects',
    component: ProjectList
  },
  {
    path: '/sites/:id',
    name: 'SiteDetail',
    component: SiteDetail,
    props: true
  },
  //NESTED ROUTES UNDER PROJECT NAME
  {
    path: '/:name/',
    component: ProjectHome,
    props: true,
    children: [
      {
        path: '',
        name: 'ProjectSummary',
        component: ProjectSummary
      },
      {
        path: 'observations',
        name: 'ProjectObservations',
        component: ProjectObsMap
      },
      {
        path: 'observations/:id',
        name: 'ObsDetail',
        component: ProjectObsDetail,
        props: true
      },
      {
        path: 'posts',
        name: 'ProjectPosts',
        component: ProjectPostList
      },
      {
        path: 'posts/:id',
        name: 'PostDetail',
        component: ProjectPostDetail,
        props: true
      },
      {
        path: 'post-new',
        name: 'posts-new',
        component: ProjectPostNew
      },
      {
        path: 'maps',
        name: 'ProjectMaps',
        component: ProjectMapList
      },
      {
        path: 'maps/:id',
        name: 'MapDetail',
        component: ProjectMapDetail,
        props: true
      },
      {
        path: 'locate',
        name: 'locate',
        component: ProjectLocate
      },
      {
        path: 'questions',
        name: 'ProjectQuestions',
        component: ProjectQuestionList
      },
      {
        path: 'observation-new',
        name: 'obs-new',
        component: ProjectObsNew,
        props: true
      },
      {
        path: 'outbox',
        name: 'outbox',
        component: ProjectOutbox
      }
    ]
  },

  {
    path: '/403/',
    name: '403',
    component: Unauthorized
  },
  {
    path: '/404/',
    name: '404',
    component: NotFound
  },
  {
    path: '/500/',
    name: '500',
    component: ServerError
  },
  {
    path: '/network-issue/',
    name: 'network-issue',
    component: NetworkIssue
  },

  { path: '*', redirect: '/404/' }
  // route level code-splitting
  // this generates a separate chunk (about.[hash].js) for this route
  // which is lazy-loaded when the route is visited.
  // component: () =>
  //   import(/* webpackChunkName: "about" */ "../views/About.vue")
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  //new routes route to top of page
  scrollBehavior(to, from, savedPosition) {
    return { x: 0, y: 0 }
  }
})

export default router
