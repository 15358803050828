<template>
  <b-container>
    <div class="warning">
      This web app is undergoing frequent changes, and is now offline-capable
      for signed-in users
      <router-link :to="{ name: 'help' }">(see Help page for tips)</router-link
      >. If you have an older version of this site cached in your browser, you
      should refresh this page at least twice. It will also help if you log out
      and log in again.
    </div>
    <p>
      This web app is a
      <b>questioning and reporting framework</b> that supports locally driven,
      participatory projects that are learning to monitor and manage
      <em>wholes</em> such as watershed function, soil health, and local
      economics.
    </p>
    <img
      src="/m/i/1/sask_bground_for_site_header.jpg"
      width="100%"
      class="mb-6"
    />
    <b-row>
      <b-col class="left">
        <h2>From surveillance . . .</h2>
        <p>compliance and certification monitoring</p>
        <p>one-way information pipelines</p>
        <p>expert judgment, predictions, and models</p>
        <p>academic research and publication</p>
      </b-col>
      <b-col class="right">
        <h2>to participatory community science</h2>
        <p>shared intelligence on fundamental processes</p>
        <p>participatory evidence, learning, interpretation</p>
        <p>
          flexible, adaptable, portable data formats that support feedback and
          dialogue
        </p>
        <p>permission and flexibility to ask new and different questions</p>
      </b-col>
    </b-row>
    <p>
      If your primary focus is surveillance or judgment, there are better tools
      out there than this one. However, if your project is 1) already a local
      learning network, and 2) needing or wanting to shift toward more
      participatory feedback and learning, to bridge the gap between monitoring
      "insiders" and "outsiders," soilhealth.app may be a good choice. See
      <router-link :to="{ name: 'about' }">the about page</router-link>&nbsp;for
      more details, and how you can start your own local project.
    </p>
    <p v-if="!authObserver">
      This app is for participants in group projects only. If you are interested
      in participating in a project, contact the project's coordinator.
    </p>
  </b-container>
</template>

<script>
import { contextMixin } from '@/mixins/context.js'

export default {
  title: 'soilhealth.app Home',
  name: 'Home',
  mixins: [contextMixin]
}
</script>
<style scoped>
p {
  font-size: 1.5rem;
}
.warning {
  background-color: rgb(238, 153, 153);
  font-size: 1rem;
  padding: 3px;
}
.left {
  background-color: rgb(80, 80, 80);
  color: white;
}
.right {
  background-color: rgb(253, 236, 169);
  color: rgb(22, 2, 134);
}
</style>
