<template>
  <div class="formfieldBorder">
    <b-form-group
      label-for="textArea"
      :label="block.label"
      :description="block.description"
    >
      <b-form-textarea
        id="textArea"
        size="lg"
        v-model="fieldvalue"
        :placeholder="block.placeholder"
        :state="fieldvalue.length >= block.min"
        :rows="block.rows"
      ></b-form-textarea>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'TextAreaInput',
  data() {
    return {
      fieldvalue: ''
    }
  }
}
</script>
