import axios from 'axios'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.xsrfHeaderName = 'X-CSRFTOKEN'

const api = axios.create({
  baseURL: 'https://soilhealth.app/api/',
  // withCredentials: false,
  headers: {
    'X-CSRFTOKEN': 'csrftoken'
    // Accept: 'application/json',
    // 'Content-type': 'application/json'
  },
  timeout: 100000
})

export default api
