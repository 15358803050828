<template>
  <div class="formfieldBorder">
    <b-form-checkbox
      size="lg"
      v-model="fieldvalue"
      :autofocus="block.autofocus"
      >{{ block.label }}</b-form-checkbox
    >
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'BooleanInput',
  data() {
    return { fieldvalue: null }
  }
}
</script>
