<template>
  <div>
    <Loading v-if="loading && delayElapsed" />
    <Error v-if="error" :error="error" />
    <div v-if="post">
      <h3>{{ post.title }}</h3>

      <p>
        posted by
        <b>{{ post.author_name }}</b>
        on {{ post.date }}
      </p>
      <div class="postdetail" v-html="post.body"></div>
      <div v-if="post.imageURL">
        <img :src="this.mediaURL + post.imageURL" />
        <p v-if="post.caption" class="picture_caption">{{ post.caption }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import api from '@/api.js'
import GlobalComponents from '@/globalComponents.js'
import { contextMixin } from '@/mixins/context.js'
export default {
  title: 'Post',
  name: 'PostDetail',
  props: ['id'],
  components: { ...GlobalComponents },
  mixins: contextMixin,
  data() {
    return {
      post: null,
      error: null,
      loading: false,
      delayElapsed: false,
      mediaURL: '/m/'
    }
  },
  methods: {
    fetchPost() {
      this.error = this.post = null
      this.delayElapsed = false
      this.loading = true
      api
        .get(this.projectName + '/posts/' + this.id)
        .then((response) => {
          this.post = response.data
        })
        .catch((error) => {
          this.error = error
          this.loading = false
          this.error = error
        })
        .finally(() => {
          this.loading = false
        })
      setTimeout(() => {
        this.delayElapsed = true
      }, 200)
    }
  },
  created() {
    this.fetchPost()
  }
}
</script>
<style scoped>
.postdetail >>> img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  max-width: 100%;
  height: auto;
}
.picture_caption {
  font-style: italic;
  text-align: center;
}
</style>
