<template>
  <div class="alert alert-warning">
    <b-img
      blank
      blank-color="#ff7777"
      class="mr-2"
      width="12"
      height="12"
    ></b-img>
    <h3>{{ string }}</h3>
    <h4>{{ explanation }}</h4>
  </div>
</template>

<script>
export default {
  props: ['error'],
  computed: {
    string() {
      return this.error.toString()
    },
    explanation() {
      if (this.string.includes('404')) {
        return 'page or data not found, does not exist on this server'
      } else if (this.string.includes('Network Error')) {
        return 'Cannot connect to server or database'
      } else if (this.string.includes('401')) {
        return 'Forbidden'
      } else if (this.string.includes('400')) {
        return 'Bad request: data not properly formatted'
      } else if (this.string.includes('403')) {
        return 'You are not authorized and may need to log in for better access'
      } else {
        return 'Something went wrong.'
      }
    }
  }
}
</script>
