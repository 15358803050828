<template>
  <b-container>
    <Loading v-if="loading" />
    <Error v-if="error" :error="error" />
    <b-row>
      <h3>
        <span v-if="!loading" style="color: blue">
          {{ filteredProjects.length }}
        </span>
        projects
      </h3>
      <p>
        Most projects will have a combination of public and private data. To
        view private data, or to enter data, you must be a participating
        observer in the project (ask the project coordinator).
      </p>
    </b-row>
    <b-row v-if="!loading">
      <p>Enter any search term to filter list of projects</p>

      <div>
        <b-form inline @submit.prevent>
          <b-input
            v-model="search"
            class="m-3"
            placeholder="Search"
            type="search"
          ></b-input>

          <b-button class="m-3" variant="info" @click="modalShow = !modalShow"
            >Search tips</b-button
          >
        </b-form>

        <div
          v-for="project in filteredProjects"
          :key="project.id"
          class="project-card"
        >
          <h2>
            <a :href="project.name">{{ project.name }}</a>
          </h2>
          <div v-html="sliced(project.description)"></div>
          <h5>
            <b-badge variant="primary">{{ project.observations }}</b-badge
            >&nbsp;observations
          </h5>
          <h5>
            <b-badge variant="success">{{ project.participants }}</b-badge
            >&nbsp;participating observers
          </h5>
        </div>
      </div>
    </b-row>
    <b-row>
      <div>
        <b-modal v-model="modalShow" ok-only>
          <template v-slot:modal-title>Search tips</template>
          <template v-slot:default
            >Searches text on project home pages, not case-sensitive.</template
          >
        </b-modal>
      </div>
    </b-row>
  </b-container>
</template>
<script>
import api from '@/api.js'
import GlobalComponents from '@/globalComponents.js'
import { contextMixin } from '@/mixins/context.js'

export default {
  title: 'Project list',
  name: 'ProjectList',
  mixins: [contextMixin],

  data() {
    return {
      loading: true,
      error: false,
      modalShow: false,
      search: '',
      projects: []
    }
  },
  computed: {
    filteredProjects() {
      return this.projects
        .filter((project) => {
          return project.observations > 0
        })
        .filter((project) => {
          return JSON.stringify(project).match(new RegExp(this.search, 'i'))
        })
    }
  },
  methods: {
    sliced(html) {
      if (typeof html == 'string' || html instanceof String) {
        html = html.slice(0, 250).replace(/<[^>]*>?/gm, ' ')
        return html + ' . . . '
      }
    },
    getProjects() {
      api
        .get('projects/')
        .then((response) => {
          this.projects = response.data
          this.loading = false
        })
        .catch((error) => {
          this.loading = false
          this.error = error
        })
    }
  },
  mounted() {
    this.getProjects()
  }
}
</script>

<style scoped>
.project-card {
  border: 2px solid blue;
  border-radius: 20px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 200, 0.3),
    0 6px 20px 0 rgba(0, 0, 200, 0.19);
  margin: 11px;
  padding: 7px;
}

.participant {
  color: blue;
  cursor: pointer;
  background-color: yellow;
}
.participant:hover {
  text-decoration: underline;
}
</style>
