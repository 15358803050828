<template>
  <div class="formfieldBorder">
    <b-form-group
      :label="block.name"
      label-for="datepick"
      :description="block.description"
    >
      <b-form-datepicker
        id="datepick"
        size="lg"
        class="mb-2"
        v-model="fieldvalue"
        :autofocus="block.autofocus"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'DateInput',
  data() {
    return {
      // initialize today
      fieldvalue: new Date().toISOString().slice(0, 10)
    }
  }
}
</script>
