<template>
  <div>
    <Loading v-if="loading && delayElapsed">fetching observations ...</Loading>
    <Error v-if="error" :error="error" />
    <div v-if="features">
      <div>
        <b-row>
          <h3 v-if="thisProject">
            <span style="color: blue">
              {{ filteredFeatures.length }}
            </span>
            sites with observations matching the search term
          </h3>
          <h3 v-else>
            <span class="alert alert-warning">Error: No project selected.</span>
          </h3>
        </b-row>
        <b-row>
          <p>
            Enter any search term, and Update the map to see sites with matching
            observations. Click a marker for a list of observations.
          </p>

          <div>
            <b-form inline @submit.prevent>
              <b-input
                v-model="search"
                class="m-3"
                placeholder="Search"
                type="search"
              ></b-input>

              <b-button
                type="submit"
                class="m-3"
                variant="primary"
                @click="this.reRenderMap"
                >Update map</b-button
              >

              <b-button variant="info" @click="modalShow = !modalShow"
                >Search tips</b-button
              >
            </b-form>
          </div>

          <Map :features="filteredFeatures" :key="baseMapKey"></Map>
        </b-row>
        <hr style="color: #9aa0e6; background-color: #9aa0e6; height: 10px" />
        <b-row>
          <div class="col-div">
            <h3>Your most recent observations</h3>
            <div v-if="myObs" v-html="makeTable(myObs)"></div>
            <div v-else>
              <p>
                Empty. Either you have made no observations, or you aren't
                logged in.
              </p>
            </div>
          </div>
          <div v-if="latestObs" class="col-div">
            <h3>
              Latest
              <span style="color: red" v-if="!status">public </span>observations
            </h3>
            <div v-html="makeTable(latestObs)"></div>
          </div>
        </b-row>
      </div>

      <b-row>
        <div>
          <b-modal v-model="modalShow" ok-only>
            <template v-slot:modal-title>Search tips</template>
            <template v-slot:default>
              This offers a simple text-based filter, not case-sensitive. Enter
              your term such as Infiltration, Image, a site name, Comment,
              nitrogen, alfalfa, a person's name, or a date (YYYY-MM-DD) such as
              2020-05-24 (day) or 2019-05 for a month. The number of results
              will show in the headline, and you can press Update map to show
              these.
            </template>
          </b-modal>
        </div>
      </b-row>
    </div>
  </div>
</template>

<script>
import api from '@/api.js'
import GlobalComponents from '@/globalComponents.js'
import { contextMixin } from '@/mixins/context.js'

export default {
  title: 'Observations',
  name: 'ObsMap',
  mixins: [contextMixin],
  components: {
    ...GlobalComponents
  },
  props: ['thisProject'],
  data() {
    return {
      loading: false,
      error: null,
      delayElapsed: false,
      modalShow: false,
      search: '',
      baseMapKey: 0,
      features: null,
      myObs: null,
      latestObs: null
    }
  },
  computed: {
    filteredFeatures() {
      return (
        this.features
          // use map function first so as not to overwrite this.features
          .map((feature) => ({
            ...feature,
            properties: {
              name: feature.properties.name,
              site_observations: feature.properties.site_observations.filter(
                (obs) => {
                  return JSON.stringify(obs).match(new RegExp(this.search, 'i'))
                }
              )
            }
          }))
          .filter((feature) => feature.properties.site_observations.length)
      )
    }
  },

  methods: {
    makeTable: function (obj) {
      var t = '<table>'
      if (obj.length) {
        for (var i = 0; i < obj.length; i++) {
          t +=
            '<tr><td><a href="/' +
            this.projectName +
            '/observations/' +
            obj[i].id +
            '/" target="_blank">' +
            obj[i].properties.Label +
            '</a></br></td></tr>'
        }
      }
      t += '</table>'
      return t
    },
    fetchSites() {
      this.error = this.features = null
      this.loading = true
      this.delayElapsed = false
      api
        .get(this.thisProject.name + '/sites/')
        .then((response) => {
          this.features = response.data.features
        })
        .catch((error) => {
          this.error = error
        })
        .finally(() => {
          this.loading = false
        })
      setTimeout(() => {
        this.delayElapsed = true
      }, 300)
    },
    fetchMyObs() {
      if (this.authObserver) {
        this.myObs = null
        api
          .get(
            this.thisProject.name +
              '/observations/?observer_id=' +
              this.authObserver.my_id +
              '&page=1'
          )
          .then((response) => {
            this.myObs = response.data.results.features
          })
          .catch((error) => {
            this.error = error
          })
      }
    },
    fetchLatestObs() {
      this.latestObs = null
      api
        .get(this.thisProject.name + '/observations/?page=1')
        .then((response) => {
          this.latestObs = response.data.results.features
        })
        .catch((error) => {
          this.error = error
        })
    },
    reRenderMap() {
      this.baseMapKey += 1
    }
  },
  created() {
    this.fetchSites()
  },
  mounted() {
    // these won't take as long, and render below
    this.fetchMyObs()
    this.fetchLatestObs()
  }
}
</script>
<style scoped>
.col-div {
  float: left;
  width: 50%;
  border-left: 1pt solid black;
  padding: 6px;
}
.col-div >>> tr {
  border-top: 1pt solid black;
}
.col-div >>> td {
  padding: 4px;
}
</style>
