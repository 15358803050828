<template>
  <div class="formfieldBorder">
    <b-form-group
      label-for="text"
      :label="block.label"
      :description="block.description"
    >
      <b-form-input
        id="text"
        type="text"
        size="lg"
        v-model="fieldvalue"
        :placeholder="block.placeholder"
        :state="fieldvalue.length >= block.min"
        :autofocus="block.autofocus"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'TextInput',
  data() {
    return {
      fieldvalue: ''
    }
  }
}
</script>
