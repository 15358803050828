<template>
  <div>
    <Loading v-if="loading && delayElapsed" />
    <Error v-if="error" :error="error" />
    <div v-if="maps">
      <b-row>
        <h3 v-if="thisProject">
          <span style="color: blue">{{ filteredMaps.length }}</span>
          Maps
        </h3>
        <h3 v-else>
          <span class="alert alert-warning">Error: No project selected.</span>
        </h3>
      </b-row>
      <b-row>
        <p>Enter any search term to filter Maps by keyword.</p>

        <div>
          <b-form inline @submit.prevent>
            <b-input
              v-model="search"
              class="m-3"
              placeholder="Search"
              type="search"
            ></b-input>
          </b-form>
        </div>
      </b-row>
      <div v-if="maps.length">
        <div v-for="map in filteredMaps" :key="map.id" class="map">
          <router-link :to="{ name: 'MapDetail', params: { id: map.id } }">
            <h4>{{ map.name }}</h4>
          </router-link>
          <p>by {{ map.author_name }}</p>
          <div>{{ sliced(map.description) }} . . .</div>
        </div>
      </div>
      <div v-else>
        <h2>No maps yet.</h2>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api.js'
import GlobalComponents from '@/globalComponents.js'
export default {
  title: 'Map list',
  name: 'MapList',
  props: ['thisProject'],
  components: { ...GlobalComponents },
  data() {
    return {
      maps: null,
      loading: false,
      error: null,
      delayElapsed: false,
      search: ''
    }
  },
  computed: {
    filteredMaps() {
      return this.maps.filter((map) => {
        return JSON.stringify(map).match(new RegExp(this.search, 'i'))
      })
    }
  },

  methods: {
    sliced(html) {
      if (typeof html == 'string' || html instanceof String) {
        return html.slice(0, 120).replace(/<[^>]*>?/gm, ' ')
      }
    },
    fetchMaps() {
      this.error = this.maps = null
      this.loading = true
      this.delayElapsed = false
      api
        .get(this.thisProject.name + '/maps/')
        .then((response) => {
          this.maps = response.data
        })
        .catch((error) => {
          this.error = error
        })
        .finally(() => {
          this.loading = false
        })
      setTimeout(() => {
        this.delayElapsed = true
      }, 300)
    }
  },

  created() {
    this.fetchMaps()
  }
}
</script>
<style scoped>
.map {
  border: 2px solid rgb(76, 0, 155);
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 153, 255, 0.25),
    0 6px 20px 0 rgba(0, 153, 255, 0.25);
  margin: 8px;
  padding: 4px;
}
.map:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 100, 200, 0.7),
    0 6px 20px 0 rgba(0, 100, 200, 0.7);
  margin: 8px;
  padding: 4px;
}
</style>
