<template>
  <div>
    <Error v-if="error" :error="error" />
    <h3>Add a location or site</h3>
    <div>
      <b-form-group>
        <p class="hint">Choose your location mode:</p>
        <b-form-radio-group
          id="btn-radios-3"
          v-model="locationMode"
          buttons
          stacked
          button-variant="outline-primary"
          size="lg"
          name="radio-btn-stacked"
          @change="call_location_mode"
        >
          <b-form-radio value="mapLocate">GPS point</b-form-radio>
          <b-form-radio value="clickMap">
            <span v-if="online">Click on map or<br /></span>
            enter coordinates
          </b-form-radio>
          <b-form-radio v-if="online" value="drawPolygon"
            >Draw a polygon</b-form-radio
          >
        </b-form-radio-group>
        <b-button
          variant="info"
          class="m-3"
          size="lg"
          @click="modalShow = !modalShow"
          >Location help</b-button
        >
      </b-form-group>
      <p class="hint">{{ hint }}</p>
    </div>

    <div id="mapContainer" class="map"></div>
    <b-form @submit.prevent="onSubmit">
      <b-form-group
        label-cols="4"
        label-cols-lg="2"
        label-size="lg"
        label="Site name or identifier"
        label-for="sitename"
        description="Start with a letter, at least 3 characters"
      >
        <b-form-input
          id="sitename"
          class="mt-3"
          v-model="name"
          placeholder="site name or ID (required)"
          size="lg"
          :state="name.length >= 3"
          required
        />
      </b-form-group>
      <div v-if="locationMode != 'drawPolygon'">
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Latitude"
          label-for="lat"
          description="decimal degrees only"
        >
          <b-form-input
            id="lat"
            size="lg"
            type="number"
            step=".0000001"
            v-model.number="latitude"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Longitude"
          label-for="lng"
          description="decimal degrees only"
        >
          <b-form-input
            id="lng"
            size="lg"
            type="number"
            step=".0000001"
            v-model.number="longitude"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          v-if="locationMode == 'mapLocate'"
          label-cols="4"
          label-cols-lg="2"
          label-size="lg"
          label="Accuracy (m)"
          label-for="acc"
        >
          <b-form-input
            id="acc"
            size="lg"
            type="number"
            v-model.number="accuracy"
          ></b-form-input>
        </b-form-group>
        <b-button
          v-if="locationMode == 'clickMap'"
          class="float-right w-50 p-3"
          @click="go_to_coordinates"
          variant="primary"
          d-inline
          size="lg"
          >Center map</b-button
        >
      </div>
      <b-form-group
        v-if="locationMode == 'drawPolygon'"
        label-cols="4"
        label-cols-lg="2"
        label-size="lg"
        label="Polygon"
        description="You may also enter a polygon here in valid GeoJSON format"
        label-for="coords"
      >
        <b-form-input
          id="coords"
          size="lg"
          type="text"
          v-model="polygon"
        ></b-form-input>
      </b-form-group>
      <b-button
        type="submit"
        name="submit"
        variant="dark"
        size="lg"
        class="m-5 w-75"
        >Submit site</b-button
      >
    </b-form>
    <Error v-if="error" :error="error" />

    <b-modal v-model="modalShow" ok-only>
      <template v-slot:modal-title>Location help</template>
      <template v-slot:default>
        <p>There are three ways to get a location:</p>
        <ol>
          <li>
            Use your device's GPS receiver, or the location of its network
            connection. This is the default and will be activated when this page
            loads, and it may not happen instantly. If you get a "Location
            error" you may need to allow your browser to get your location. See
            links below for more info on Android and Apple devices. If you are
            moving around, be sure to reload the page when you need to get the
            location. If you are offline, you will not see a background map.
          </li>
          <li>
            Click on the map to set a point. You may then edit or enter its
            coordinates in decimal degrees of latitude and longitude. Use
            negative numbers for longitude in the western hemisphere, and for
            latitude in the southern hemisphere. Clicking on the map will also
            recenter it. (Requires network connection.)
          </li>
          <li>
            Most observations can be entered at a point location, but you may
            also draw a polygon using the polygon tool. (Requires network
            connection.)
          </li>
        </ol>
        <p>
          Make the appropriate choice to define a new site.
          <b>Your device's location settings</b> will affect the accuracy of
          your location. For help with location settings:
        </p>
        <p>
          <a
            href="https://support.google.com/android/answer/3467281?hl=en"
            target="_blank"
            >Android</a
          >
        </p>
        <p>
          <a href="https://support.apple.com/en-us/HT207092" target="_blank"
            >iPhone</a
          >
        </p>
      </template>
    </b-modal>
  </div>
</template>

<script>
// import api from '@/api.js'
import Error from '@/components/Error.vue'
import localForageMixin from '@/mixins/localForageMixin.js'
import onlineMixin from '@/mixins/onlineMixin.js'
export default {
  title: 'Locate',
  name: 'Locate.vue',
  mixins: [localForageMixin, onlineMixin],
  components: { Error },

  data() {
    return {
      error: null,
      map: null,
      marker: null,
      circle: null,
      latitude: 45,
      longitude: -117,
      name: '',
      accuracy: null,
      polygon: null,
      locationMode: 'mapLocate',
      hint: '',
      modalShow: false,
      crosshairs: false,
      drawControl: null,
      drawnItems: null
    }
  },

  methods: {
    initMode() {
      // RESET ALL BUT MARKER
      this.map.stopLocate()
      if (this.marker) {
        this.map.removeLayer(this.marker)
      }
      if (this.circle) {
        this.map.removeLayer(this.circle)
      }

      if (this.drawnItems) {
        this.map.removeLayer(this.drawnItems)
      }
      if (this.drawControl) {
        this.map.removeControl(this.drawControl)
      }
      if (this.crosshairs) {
        L.DomUtil.removeClass(this.map._container, 'crosshair-cursor-enabled')
        this.crosshairs = false
      }
    },
    call_location_mode(checked) {
      this[checked]()
    },
    go_to_coordinates() {
      this.marker.setLatLng([this.latitude, this.longitude])
      this.map.setView([this.latitude, this.longitude], 12)
    },
    setMarker(e) {
      this.marker.setLatLng(e.latlng).addTo(this.map)
      this.latitude = parseFloat(e.latlng.lat.toFixed(7))
      this.longitude = parseFloat(e.latlng.lng.toFixed(7))
      // when you cross the dateline
      this.longitude = parseFloat(
        ((((this.longitude % 360) + 540) % 360) - 180).toFixed(7)
      )
      this.map.setView(e.latlng)
    },
    clickMap() {
      this.initMode()
      this.hint =
        'Enter coordinates in decimal degrees. If you are online, you can move and zoom the map and click to select a new point.'
      L.DomUtil.addClass(this.map._container, 'crosshair-cursor-enabled')
      this.crosshairs = true

      this.map.on('click', this.setMarker)
    },
    drawPolygon() {
      this.initMode()
      this.map.off('click', this.setMarker)
      this.hint =
        'Most observations are best connected with a point site. For a polygon, you must be online to use this option. Select the polygon tool and click on map to define, edit, and save a single polygon. You can also enter a polygon geometry in the Polygon field below in GeoJSON format.'
      this.drawnItems = new L.FeatureGroup()
      this.map.addLayer(this.drawnItems)
      this.drawControl = new L.Control.Draw({
        draw: {
          polyline: false,
          polygon: true,
          rectangle: false,
          circle: false,
          marker: false,
          circlemarker: false
        },
        edit: {
          featureGroup: this.drawnItems
        }
      })
      this.map.addControl(this.drawControl)
      this.map.on(L.Draw.Event.CREATED, (event) => {
        var layer = event.layer
        this.drawnItems.addLayer(layer)
        // store the first polygon's geojson geometry
        this.polygon = JSON.stringify(
          this.drawnItems.toGeoJSON().features[0].geometry
        )
      })
    },
    initMap() {
      this.map = L.map('mapContainer').fitWorld()
      var streets = L.tileLayer(
        'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        {
          maxZoom: 18,
          attribution:
            '&copy; <a href="http://osm.org/copyright">OpenStreetleafletMap</a> contributors'
        }
      ).addTo(this.map)

      var satellite = L.tileLayer(
        'https://api.tiles.mapbox.com/v4/{id}/{z}/{x}/{y}.png?access_token={accessToken}',
        {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors, <a href="https://creativecommons.org/licenses/by-sa/2.0/">CC-BY-SA</a>, Imagery © <a href="https://www.mapbox.com/">Mapbox</a>',
          maxZoom: 18,
          id: 'mapbox.satellite',
          accessToken:
            'pk.eyJ1Ijoic29pbGNhcmJvbiIsImEiOiJja2ZjdWZ3YTgwMDhxMnF0N216NTE1OTMyIn0.kI-dBmCGoGBbrahF-sQFgA'
        }
      ).addTo(this.map)
      var baseMaps = {
        streets: streets,
        satellite: satellite
      }
      L.control.layers(baseMaps).addTo(this.map)
    },
    mapLocate() {
      this.initMode()
      this.hint =
        "Your device's GPS or internet connection should locate itself. If you are offline you may not see a map."
      this.map
        .locate({
          setView: true,
          maxZoom: 18,
          watch: true,
          enableHighAccuracy: true,
          timeout: 20000
        })
        .on('locationfound', (e) => {
          var radius = e.accuracy / 2
          if (this.marker) {
            this.map.removeLayer(this.marker)
          }
          if (this.circle) {
            this.map.removeLayer(this.circle)
          }
          this.circle = L.circle(e.latlng, radius).addTo(this.map)
          this.marker = L.marker(e.latlng).addTo(this.map)
          this.latitude = parseFloat(e.latlng.lat.toFixed(7))
          this.longitude = parseFloat(e.latlng.lng.toFixed(7))
          this.accuracy = Math.ceil(e.accuracy)
          // this.map.stopLocate()
        })
        .on('locationerror', (error) => {
          alert('LOCATION ERROR: ', error.message)
          if (this.marker) {
            this.map.removeLayer(this.marker)
            this.map.removeLayer(this.circle)
            this.marker = undefined
          }
          this.map.stopLocate()
        })
    },
    onSubmit() {
      var site = {}
      site.name = this.name
      site.geometry = this.polygon
        ? this.polygon
        : {
            type: 'Point',
            coordinates: [this.longitude, this.latitude]
          }
      // PUT SITE INTO LOCALFORAGE siteTable with name as key
      localStorage.setItem('site', JSON.stringify(site))
      this.siteTable
        .setItem(site.name, site)
        .then(() => {
          this.siteLength += 1
          this.$router.push({ name: 'ProjectQuestions' })
        })
        .catch((error) => {
          this.error = error
        })
    }
  },

  mounted() {
    this.initMap()
    this.mapLocate()
  },
  beforeDestroy() {
    if (this.map) {
      this.map.remove()
      this.map.stopLocate()
    }
  }
}
</script>

<style scoped>
#mapContainer {
  width: 80vw;
  height: 70vh;
}
.hint {
  font-size: 1.4em;
}
.leaflet-container.crosshair-cursor-enabled {
  cursor: crosshair;
}
</style>
