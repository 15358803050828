<template>
  <div v-if="!loading">
    <template>
      <h4>
        These are the available questions or data types for this project.
        <span v-if="this.site && this.status"
          >You may enter an observation on
          <span class="siteName">{{ site.name }}</span> site by choosing one of
          the following:</span
        >
      </h4>
      <div
        class="question"
        v-for="question in thisProject.questions"
        :key="question.id"
        @click="storeQuestion(question)"
      >
        <router-link :to="{ name: 'obs-new', params: { question: question } }">
          <img
            v-if="question.icon"
            :src="question.icon"
            align="left"
            width="60"
          />
          <h4>{{ question.name }}:</h4>
          {{ question.description }}
        </router-link>
      </div>
    </template>
    <p>
      These questions or data forms are flexible and can be used in many ways.
      If you have ideas for other questions or data forms you'd like to have
      available, contact your project coordinator with the specifics.
    </p>
  </div>
</template>

<script>
import { contextMixin } from '@/mixins/context.js'
export default {
  title: 'Question list',
  name: 'QuestionList',
  props: ['thisProject'],
  mixins: [contextMixin],
  data() {
    return {
      loading: true
    }
  },
  methods: {
    storeQuestion(obj) {
      localStorage.question = JSON.stringify(obj)
    }
  },

  mounted() {
    this.loading = false
    localStorage.removeItem('question')
  }
}
</script>

<style scoped>
.question {
  border: 2px solid rgb(0, 0, 0);
  border-radius: 15px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 100, 0.3),
    0 6px 20px 0 rgba(0, 0, 100, 0.19);
  margin: 9px;
  padding: 7px;
}
img {
  padding: 10px;
}
.siteName {
  color: blue;
  font-style: italic;
}
.question:hover {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 100, 0.8),
    0 6px 20px 0 rgba(0, 0, 100, 0.6);
}
</style>
