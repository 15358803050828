<template>
  <div class="formfieldBorder">
    <b-form-group :label="block.label" :description="block.description">
      <b-form-file
        size="lg"
        v-model="fieldvalue"
        :state="Boolean(fieldvalue)"
        :placeholder="block.placeholder"
        @change="onFileSelected"
      ></b-form-file>
      <div id="preview">
        <img v-if="url" :src="url" />
      </div>
    </b-form-group>
  </div>
</template>
<script>
export default {
  props: {
    block: Object
  },
  name: 'ImageInput',
  data() {
    return {
      fieldvalue: null,
      url: null
    }
  },
  methods: {
    onFileSelected(event) {
      this.url = URL.createObjectURL(event.target.files[0])
    }
  }
}
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 100%;
  max-height: 500px;
}
</style>
