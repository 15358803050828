<template>
  <b-container>
    <h1>403 Unauthorized</h1>
    <p>
      The
      <em>{{ resource }}</em>
      you're looking for is private, viewable by participating project members
      only.
    </p>
    <router-link :to="{ name: 'home' }">Home page with menu</router-link>
  </b-container>
</template>

<script>
export default {
  props: {
    resource: {
      type: String,
      required: true
    }
  }
}
</script>

<style></style>
