<template>
  <div class="container">
    <div class="d-flex justify-content-center mb-3">
      <b-spinner
        style="width: 7rem; height: 7rem"
        variant="primary"
        label="Loading..."
      ></b-spinner>
    </div>
    <p class="animate-flicker"><slot>Loading . . . </slot></p>
  </div>
</template>
<script>
export default {
  name: 'Loading'
}
</script>

<style scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }
}
.animate-flicker {
  margin-top: 10px;
  text-align: center;
  animation: fadeIn 1s infinite alternate;
  font: italic bold 2em/1 Bodoni, serif;
  color: #007bff;
}
</style>
