<template>
  <div>
    <div v-html="thisProject.description"></div>
    <h5>
      <b-badge variant="primary">{{ thisProject.observations }}</b-badge
      >&nbsp;observations
    </h5>
    <h5>
      <b-badge variant="success">{{ thisProject.participants }}</b-badge
      >&nbsp;participating observers
    </h5>
    <OutboxModal v-if="outbox && online"></OutboxModal>
  </div>
</template>

<script>
import GlobalComponents from '@/globalComponents.js'
export default {
  components: {
    ...GlobalComponents
  },
  props: ['thisProject']
}
</script>
