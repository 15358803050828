<template>
  <b-container>
    <h1>Uh-oh . . .</h1>
    <h3>Server Error</h3>
    <router-link :to="{ name: 'home' }">Home page with menu</router-link>
  </b-container>
</template>

<script>
export default {}
</script>

<style></style>
