import FeatureMap from '@/components/FeatureMap.vue'
import ObsConfirm from '@/components/ObsConfirm.vue'
import Map from '@/components/Map.vue'
import Loading from '@/components/Loading.vue'
import Error from '@/components/Error.vue'
export default {
  FeatureMap,
  ObsConfirm,
  Map,
  Loading,
  Error
}
