import BooleanInput from '@/components/formfields/BooleanInput.vue'
import CheckboxInput from '@/components/formfields/CheckboxInput.vue'
import DateInput from '@/components/formfields/DateInput.vue'
import FileInput from '@/components/formfields/FileInput.vue'
import Help from '@/components/formfields/Help.vue'
import ImageInput from '@/components/formfields/ImageInput.vue'
import Link from '@/components/formfields/Link.vue'
import Note from '@/components/formfields/Note.vue'
import NumberInput from '@/components/formfields/NumberInput.vue'
import RadioInput from '@/components/formfields/RadioInput.vue'
import RangeInput from '@/components/formfields/RangeInput.vue'
import TextAreaInput from '@/components/formfields/TextAreaInput.vue'
import TextInput from '@/components/formfields/TextInput.vue'
import SoilAnalysis from '@/components/formfields/SoilAnalysis.vue'
import Timer from '@/components/formfields/Timer.vue'

export default {
  BooleanInput,
  CheckboxInput,
  DateInput,
  FileInput,
  Help,
  ImageInput,
  Link,
  Note,
  NumberInput,
  RadioInput,
  RangeInput,
  SoilAnalysis,
  TextAreaInput,
  TextInput,
  Timer
}
