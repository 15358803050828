<template>
  <b-container>
    <h3>
      soilhealth.app:
      <br />a web app for <br />participatory watershed/community science
    </h3>
    <p>by Peter Donovan</p>

    <p>
      Collaborative learning groups, peer-to-peer networking, farmer-to-farmer,
      rancher-to-rancher&mdash;these are arising in many places. Common themes
      are 1) local enough so that people can know and begin to trust each other,
      and 2) not depending entirely on expert information delivery, Powerpoints,
      or top-down technical assistance, but engaging in what I call
      <em>social learning</em>, with multiple perspectives and dialogue between
      producers or participants.
    </p>

    <img src="/m/forms/about001.jpg" width="97%" align="center" />
    <hr style="height: 5px; background-color: black" />
    <img src="/m/forms/about002.jpg" width="97%" align="center" />

    <p>
      With soilhealth.app I'm trying to offer a 3rd element or theme, a platform
      for data collection with some fine-grained sharing options (right now it's
      either public, or private to the group, but there are more possibilities;
      it's a work in progress). The platform is configurable for each project or
      group, and my vision is for it to be locally run, locally configured, with
      local coordinators for each project. If judgment, surveillance,
      compliance, or certification are the sole purposes, there may be better
      platforms than this one. I'm designing this platform for a combination of
      judgment and learning:
    </p>
    <ol>
      <li>
        JUDGMENT: Flexible repository for <em>required</em> data collection,
        including regulatory, compliance, certification monitoring, may include
        reporting to funders and other stakeholders. (Linear, closed questions,
        one size fits all.)
      </li>
      <li>
        LEARNING: Flexible, adaptable, user-friendly repository for volunteered
        monitoring data, interpretations, qualitative assessments or stories,
        participant-initiated questions, trials, and experiments aimed at
        learning what works, even learning across projects. A partial record in
        time and space of a process of a shared intelligence. (Circular, both
        closed and open questions, feedback, locally adapted.)
      </li>
    </ol>

    <p>
      In most cases we don't want to discard judgment; we depend on it for
      physical and social survival. But we can add learning, learning questions.
    </p>

    <p>
      Producer learning groups often don't consider the possibility of a data
      platform because they think of monitoring only as surveillance,
      compliance, or judgment, often done by professional consultants without
      local contexts, with data kept in silos, and don't realize there are wider
      options. Jared Talley's
      <a href="https://soilhealth.app/RMG-Montana/posts/52"
        >20-minute presentation</a
      >
      on the difference (and confusion) between monitoring for policy and
      monitoring for management is instructive.
    </p>

    <p>
      soilhealth.app is a project of the
      <a href="https://soilcarboncoalition.org" target="_blank"
        >Soil Carbon Coalition</a
      >. It is a web app, <b>a questioning and reporting framework,</b> designed
      to support and accompany learning and feedback among people who know and
      trust each other:
    </p>
    <ul>
      <li>local projects that seek to manage wholes</li>
      <li>people-centered, participatory watershed or community science</li>
      <li>
        a shared, localized, evidence-based intelligence on the complex and
        emergent relations between sunlight energy, ecosystem function, and
        human decisions.
      </li>
      <li>
        bridging the gap between "insiders" and "outsiders" in monitoring and
        management
      </li>
    </ul>

    <p>
      There is the opportunity for human society to learn how to work
      <strong>with</strong> the most powerful planetary force: coupled carbon
      and water cycling. There is also the opportunity to learn to manage
      <b>for</b> what we want and need based on biophysical feedback, rather
      than merely manage <b>against</b> what we don't want. We can facilitate
      <em>learning</em> and <em>feedback</em> on the circle of life.
    </p>
    <p>
      This learning cannot remain exclusively digital, or restricted to
      professionals and insiders. There must be a strong human element of
      growing trust and community. (See blog post about learning networks,
      external and internal motivations,
      <a
        href="https://soilcarboncoalition.org/why-learning-networks/"
        target="_blank"
        >here.</a
      >) Information technology such as soilhealth.app can play a support and
      accompaniment role:
    </p>
    <ul>
      <li>
        transparency and trust for participants, stakeholders, and supporters in
        recording and sharing evidence and feedback, and displaying observations
        and data on maps with a variety of additional layers and contexts
      </li>
      <li>serving as memory or historical record</li>
      <li>a guide for repeated and repeatable observations</li>
      <li>
        a platform that supports sharing and dialogue, a shared intelligence,
        and synergy and mutual understanding between professional, land manager,
        and citizen observations and interpretations
      </li>
    </ul>

    <h4>What soilhealth.app IS NOT:</h4>
    <ul>
      <li>
        a data repository for individuals who aren't participating in a project
      </li>
      <li>an engineer-designed silo for data, only accessible to insiders</li>
      <li>a digital substitute for real dialogue</li>
      <li>
        a substitute for enlisting or engaging people as observers,
        participants, and interpreters
      </li>
      <li>a monitoring "protocol", fixed set of indicators</li>
      <li>a program or recipe for soil health "practices"</li>
      <li>
        a tool for confiscating data, or commodifying or selling agricultural
        practices
      </li>
      <li>a magic box where you just put in numbers, and out comes meaning</li>
      <li>a tool for predicting the future</li>
    </ul>
    <p>
      Making observations and recording data can help participants understand
      what's possible, recognize and respect their own knowledge and experience,
      enriching contexts and creativity. Questions that focus on carbon and
      water cycling can help students, land managers, citizens, and
      professionals engage with the various flows and variabilities of solar
      energy, in addition to the more popular emphases on species, substances or
      elements (such as C, N, or P), problems, and practices.
    </p>

    <h2>Three generations of data collection</h2>
    <ol>
      <li>
        Data collection can be a tool of
        <strong>surveillance,</strong> for which standardized, categorized, and
        sometimes large data sets are needed. This is like the one-way mirrors
        used decades ago in psychology research that separated the observers
        from the subjects, and enabled the observers to observe the subjects and
        extract value, but not vice-versa. One modern equivalent is the
        surveillance camera. The subjects of the observation typically do not
        know how the data is being interpreted or used. Data is used by
        rule-based systems in inscrutable ways to gauge compliance, assign a
        category, make predictions and judgments, and often to extract
        commercial value by targeting a market for a product or service. It
        fosters distrust between "insiders" and "outsiders" and a top-down power
        structure of control. Much of what is termed research has tended to use
        surveillance methods and framing because it makes it easier to aggregate
        consistent data, and make conclusions that are of interest to
        researchers.
      </li>

      <li>
        Data collection can serve the purposes of feedback, and become a
        reflective
        <strong>mirror</strong> by which you see yourself, and increase your
        self-awareness, and perhaps compare yourself with some kind of average.
        This can be like knowing your bank balance, your weight, or your net
        worth, how fast you can run 5 kilometers, or approximately how well your
        soil accepts and holds water. It can be a way of asking good questions,
        and it can help you make decisions toward what you want and need.
      </li>

      <li>
        Data collection can serve as feedback, mirror, and
        <strong>
          a platform and accompaniment for a shared learning at the group or
          community level.
        </strong>
        The designs that foster this will be more collaborative and locally
        adapted. They will resemble
        <a
          href="https://soilcarboncoalition.org/pipelines-versus-platforms-power-and-the-politics-of-knowledge/"
          target="_blank"
          >platforms more than pipelines or silos.</a
        >
        They may be less aligned with rule-based systems and "best practices,"
        and more adapted to complex domains and wholes such as ecosystem
        function, with the overlapping concerns of land, people, and money. They
        may have a primary focus on energy (which includes behavior and
        economics) more than on practices, species, or substances.
      </li>
    </ol>

    <p>
      These are different purposes, and they overlap. The design of
      soilhealth.app is slanted toward the second and third generations, toward
      feedback, inquiry, participation, accompaniment, a platform for a shared
      learning, for putting data and evidence in contexts. For surveillance
      only, for "insider" information pipelines, for extraction of value outside
      the project, or the delivery of expert solutions with their requirements
      for data consistency and categorization across space, there are more
      efficient tools and systems available than this one.
    </p>

    <h2>Some features</h2>
    <ul>
      <li>
        data entry forms combine text, numbers, photos with location for display
        on simple interactive maps
      </li>
      <li>
        design and configure new, custom data forms (questions) and sources in
        minutes, whether simple citizen-usable forms, or 100-field forms for
        complicated protocols or "methodologies"
      </li>
      <li>
        mobile or desktop, logged-in users may enter data with or without
        network
      </li>
      <li>
        public data easily exported via API (application programming interface)
        and shared; observations can remain private to the group
      </li>
      <li>search by text, data type, user, date, location</li>
      <li>
        easy import or mashup of any available mapped data such as remote
        sensing, other data sets, both vector and raster: .csv, .geojson, .kml,
        tilesets, etc. (Shapefiles and other formats can be converted to these.)
      </li>
      <li>
        designed for repeatability of observations, and for displaying change
        over time in easy map-based visualizations
      </li>
      <li>
        focused more on a shared, participatory, evidence-based intelligence at
        the local and regional level
      </li>
      <li>
        <b
          >decentralized, with coordinator/facilitator roles for each
          project.</b
        >
        Coordinators can configure data forms, forms of data sharing including
        notifications or subscriptions, and context maps or mashups. They can
        grow <strong>a local data collaborative</strong>, with flexible and
        adaptable privacy, forms of engagement, connections to non-digital
        methods of participation, sharing, and interpretation.
      </li>
    </ul>

    <h4>
      because no two places, people, ranches, or farms are the same, or are
      asking the same questions!
    </h4>
    <img src="/m/i/1/know_it_with.jpg" width="100%" />

    <p>Confucius said:</p>
    <i>
      <p>
        Tell me, and I forget.
        <br />Show me, and I remember. <br />Let me do, and I understand.
      </p>
    </i>

    <p>
      Soilhealth.app is a platform for locally driven, map-based data
      presentation that has a <b>community focus</b> on a shared intelligence on
      ecosystem function, economic function, and more. We expect this will help
      grow a creative and innovative network of locally based
      shared-intelligence practitioners. If you are coordinating or wanting to
      help start a participatory watershed science effort, and would like to
      participate in the use or design of a flexible web app, please contact us:
      managingwholes.com@gmail.com or info@soilcarboncoalition.org
    </p>

    <div style="text-align: center; margin-bottom: 12px">
      <i>Humankind is nature becoming self-conscious.</i>
      <br />L'Homme est la nature prenant conscience d'elle m&ecirc;me.
      <br />Elis&eacute;e Reclus, <i>L'Homme et la Terre</i> (1905)
    </div>

    <h3>
      <a name="developer">Developer and coordinator</a>
    </h3>

    <img src="/m/i/1/peter-mugshot.jpg" align="right" width="200" />
    <p>
      <b>Peter Donovan:</b> I have a background in forestry, farm, and ranch
      work, sheep and cattle herding, Holistic Management and decision making,
      rangeland monitoring, community development.
    </p>
    <p>
      My first effort in sharing what I was learning about ecosystem process and
      function was reporting on holistic managers in the 1990s (see
      <a href="https://managingwholes.com" target="_blank">managingwholes.com</a
      >). In 2007 I founded the Soil Carbon Coalition. This second effort was
      traveling around the continent for a decade measuring soil carbon change,
      with open data. I was spread too thin, not a good context for sharing or
      fostering a shared intelligence on the circle of life. Without a coherent
      group, the context of this work was vague and unfocused. Much of the
      conversation and buzz about soil carbon quickly turned to the
      commodification of soil carbon as "offsets" which to me is the wrong
      question&mdash;for both our intentions, and our ability to implement them.
      (Vandana Shiva observes that the financialization of nature equals the
      rape of the earth.) soilhealth.app is the third try: can this help locally
      driven efforts to ask better questions, and engage more people in asking
      and answering?
    </p>

    <p>I can be reached at managingwholes.com@gmail.com</p>
  </b-container>
</template>
<script>
export default {
  title: 'About'
}
</script>
