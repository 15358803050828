<template>
  <div class="formfieldBorder">
    <b-form-group
      :label="block.label"
      v-slot="{ ariaDescribedby }"
      label-for="checkboxes"
    >
      <b-form-checkbox-group
        id="checkboxes"
        size="lg"
        stacked
        v-model="fieldvalue"
        :options="block.options"
        :aria-describedby="ariaDescribedby"
      ></b-form-checkbox-group>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'CheckboxInput',
  data() {
    return {
      fieldvalue: null
    }
  }
}
</script>
