<template>
  <div>
    <Loading v-if="loading && delayElapsed" />
    <Error v-if="error" :error="error" />
    <div id="wrapper">
      <!-- FIRST FEATURE COLUMN -->
      <div class="obsdiv" :style="divWidth" v-if="feature">
        <h3>{{ feature.properties.Label }}</h3>
        <b-button
          v-if="siteObs.length"
          class="m-2"
          variant="outline-primary"
          @click="toggle"
        >
          Compare observations
        </b-button>
        <b-button
          class="m-2"
          v-b-modal.how-to
          variant="info"
          v-if="siteObs.length"
          >How to compare observations</b-button
        >
        <b-modal id="how-to" :title="Compare" ok-only>
          <p>
            Click the "Compare observations" button to choose a comparison
            Observation from the same Site. Up to three Observations can be
            compared in columns, which are best viewed with larger screens.
          </p>
          <p>
            Comparing past with present Observations may enable you to detect
            change over time, especially if
            <b
              >these Observations are repeatable: located, observed, and
              recorded with consistency.</b
            >
            For example, repeated soil samples and analyses can be compared
            choosing the same sampleID on the label, plus a different date.
          </p>
          <p>
            To compare Observations across Sites, open them in separate tabs.
          </p>
        </b-modal>

        <div v-if="siteObsActive">
          <p>Click to load in comparison column:</p>
          <p
            class="siteObsItem"
            v-for="item in siteObs"
            :key="item.id"
            @click="fetchObs(item.id)"
          >
            {{ item.label }}
          </p>
        </div>
        <div v-html="formatTable(feature.properties)" class="obsdetail"></div>
        <hr />
        <h3>{{ feature.properties.SiteName }}</h3>
        <p>Longitude, latitude: {{ feature.geometry.coordinates }}</p>
        <FeatureMap :feature="feature"></FeatureMap>
        <b-button
          v-if="this.status"
          class="mt-4"
          variant="primary"
          @click="addObs()"
        >
          <b-icon-plus-circle />&nbsp;Add observation to this site
        </b-button>
      </div>
      <!-- SECOND FEATURE COLUMN -->
      <div class="obsdiv" :style="divWidth" v-if="feature2">
        <router-link :to="{ name: 'ObsDetail', params: { id: feature2.id } }">
          <h3>
            {{ feature2.properties.Label }}
          </h3>
        </router-link>
        <b-button
          class="ml-5"
          variant="outline-dark"
          @click="feature2 = feature3 = null"
        >
          close comparison
        </b-button>
        <div v-html="formatTable(feature2.properties)" class="obsdetail"></div>
      </div>
      <!-- THIRD FEATURE COLUMN -->
      <div class="obsdiv" :style="divWidth" v-if="feature3">
        <router-link :to="{ name: 'ObsDetail', params: { id: feature3.id } }">
          <h3>
            {{ feature3.properties.Label }}
          </h3>
        </router-link>
        <div v-html="formatTable(feature3.properties)" class="obsdetail"></div>
      </div>
    </div>
  </div>
</template>
<script>
import GlobalComponents from '@/globalComponents.js'
import api from '@/api.js'
import { contextMixin } from '@/mixins/context.js'
export default {
  name: 'ObsDetail',
  components: {
    ...GlobalComponents
  },
  mixins: [contextMixin],
  props: ['id'],
  data() {
    return {
      feature: null,
      feature2: null,
      feature3: null,
      siteObs: null,
      loading: false,
      error: null,
      delayElapsed: false,
      mediaURL: 'https://soilhealth.app/m/',
      siteObsActive: false,
      noDisplay: [
        'observer',
        'site',
        'question',
        'project',
        'ObserverID',
        'SiteID'
      ]
    }
  },
  computed: {
    divWidth() {
      if (this.feature && this.feature2 && this.feature3) {
        return { width: '33%' }
      } else if (this.feature && this.feature2 && !this.feature3) {
        return { width: '50%' }
      } else {
        return { width: '100%' }
      }
    }
  },
  methods: {
    toggle() {
      this.siteObsActive = !this.siteObsActive
    },
    formatTable(obj) {
      let t = '<table>'
      for (const [k, v] of Object.entries(obj)) {
        if (!this.noDisplay.includes(k)) {
          t += '<tr><td><b>' + k + ': </b></td>'
          if (
            (typeof v == 'string' || v instanceof String) &&
            (v.endsWith('.jpg') ||
              v.endsWith('.JPG') ||
              v.endsWith('.JPEG') ||
              v.endsWith('.jpeg') ||
              v.endsWith('.png') ||
              v.endsWith('.PNG'))
          ) {
            t +=
              '<td><a href="' +
              this.mediaURL +
              v +
              '" target="_blank"><img src="' +
              this.mediaURL +
              v +
              '" / ></a></td></tr>'
          } else if (
            (typeof v == 'string' || v instanceof String) &&
            v.endsWith('.pdf')
          ) {
            t +=
              '<td><embed src="' +
              this.mediaURL +
              v +
              '" width="300px" / ><a href="' +
              this.mediaURL +
              v +
              '" target="_blank">view in new tab</a></td></tr>'
          } else {
            t += '<td>' + v + '</td></tr>'
          }
        }
      }
      t += '</table>'
      return t
    },
    addObs() {
      var site = {}
      site.id = this.feature.properties.SiteID
      site.name = this.feature.properties.SiteName
      site.geometry = this.feature.geometry
      localStorage.setItem('site', JSON.stringify(site))
      this.$router.push({ name: 'ProjectQuestions' })
    },
    fetchObs(id) {
      this.error = null
      this.delayElapsed = false
      this.loading = true
      // myObsId = this.id
      api
        .get(this.projectName + '/observations/' + id)
        .then((response) => {
          if (!this.feature) {
            this.feature = response.data
          } else if (!this.feature2) {
            this.feature2 = response.data
          } else {
            this.feature3 = response.data
          }
          document.title = this.feature.properties.Label
          this.siteObsActive = false
          // now get the rest of the Observations for this site if they are not already present
          if (!this.siteObs) {
            this.fetchSiteObs()
          }
        })
        .catch((error) => {
          this.error = error
        })
        .finally(() => {
          this.loading = false
        })
      setTimeout(() => {
        this.delayElapsed = true
      }, 200)
    },
    fetchSiteObs() {
      api
        .get(
          '/' +
            this.projectName +
            '/sites/?site_id=' +
            this.feature.properties.SiteID
        )
        .then((response) => {
          // get site_observations array and remove this one
          this.siteObs =
            response.data.features[0].properties.site_observations.filter(
              (item) => item.id !== this.feature.id
            )
        })
        .catch((error) => {
          this.error = error
        })
    }
  },
  created() {
    this.fetchObs(this.id)
  }
}
</script>

<style scoped>
#wrapper {
  height: 100vh;
}
.obsdiv {
  /* width: 100%; */
  float: left;
  max-height: 100%;
  overflow: auto;
  padding: 4px;
  border-left: solid 2px;
}

.obsdetail {
  padding: 12px;
}
/* use deep selectors (>>>) to style v-html DOM */
.obsdetail >>> td {
  padding: 4px;
}
.obsdetail >>> tr:nth-child(even) {
  background-color: #eee;
}
.obsdetail >>> img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  max-width: 100%;
  height: auto;
}

.obsdetail >>> img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}
.siteObsItem {
  color: blue;
  line-height: 1em;
}
.siteObsItem:hover {
  background-color: rgb(230, 230, 255);
  cursor: pointer;
}
</style>
