import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import NavBar from './components/NavBar.vue'
import FeatureMap from './components/FeatureMap.vue'
import Map from './components/Map.vue'
import titleMixin from './mixins/titleMixin'
import 'nprogress/nprogress.css'
// SLIM THESE DOWN DEPENDING ON USE!

import {
  BootstrapVue,
  LayoutPlugin,
  NavbarPlugin,
  ImagePlugin,
  IconsPlugin,
  FormPlugin,
  ModalPlugin,
  BIconHouse,
  BIconDownload,
  BIconUpload,
  BIconPlusCircle
} from 'bootstrap-vue'
// import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'leaflet/dist/leaflet.css'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import Leaflet from 'leaflet'
import 'leaflet-draw'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import 'leaflet.markercluster'
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(ImagePlugin)
Vue.use(LayoutPlugin)
Vue.use(FormPlugin)
Vue.use(ModalPlugin)
Vue.use(NavbarPlugin)
Vue.mixin(titleMixin)
Vue.use(NavBar, Map, FeatureMap)

Vue.component('BIconHouse', BIconHouse) //the house icon
Vue.component('BIconDownload', BIconDownload) //the download icon
Vue.component('BIconUpload', BIconUpload) //the upload icon
Vue.component('BIconPlusCircle', BIconPlusCircle)

// this is to correct an issue where default icons are not shown
// eslint-disable-next-line
delete L.Icon.Default.prototype._getIconUrl
// eslint-disable-next-line
L.Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
})

Vue.config.productionTip = false

new Vue({
  router,
  render: (h) => h(App)
}).$mount('#app')
