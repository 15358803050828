<template>
  <div class="formfieldBorder">
    <h3>
      Analysis {{ block.formCount }} for soil sample
      <em> {{ this.$parent.$refs.formfield[0].fieldvalue }}</em>
    </h3>
    <p>
      This form can be filled out later, when you get analyses of the dated soil
      sample reported above.
    </p>
    <b-form-group
      label-for="analysisType"
      label="Analysis"
      placeholder="analysis type"
      description="Name of soil analysis, no acronyms, WITH the unit such as percent, mg/g, totaling at least 10 characters"
    >
      <b-form-input
        id="analysisType"
        type="text"
        size="lg"
        v-model="analysisType"
        :state="analysisType.length >= 10"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label-for="lab"
      label="Lab"
      description="Name of the lab, 4 chars minimum."
    >
      <b-form-input
        id="lab"
        type="text"
        size="lg"
        v-model="lab"
        placeholder="name of lab"
        :state="lab.length >= 4"
      ></b-form-input>
    </b-form-group>
    <b-form-group
      label-for="analysisDate"
      label="Date analyzed"
      description="use YYYY-MM-DD format"
    >
      <b-form-input
        id="analysisDate"
        type="text"
        size="lg"
        class="mb-2"
        placeholder="2023-01-10 for example"
        :state="analysisDate.length == 10"
        v-model="analysisDate"
      />
    </b-form-group>
    <b-form-group
      label-for="result"
      label="Result"
      description="Result of analysis. Must be a number."
    >
      <b-form-input
        id="result"
        type="number"
        size="lg"
        class="mb-2"
        v-model="fieldvalue"
        step="0.001"
        :state="fieldvalue != null"
      />
    </b-form-group>

    <b-button v-if="!added" @click="addAnotherForm"
      >Add another analysis</b-button
    >
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'SoilAnalysis',
  data() {
    return {
      analysisType: '',
      lab: '',
      analysisDate: '',
      fieldvalue: null,
      added: false
    }
  },
  computed: {
    namevalue() {
      return this.analysisType + ' by ' + this.lab + ' on ' + this.analysisDate
    }
  },
  methods: {
    addAnotherForm() {
      this.$emit('add-form', {
        component: 'SoilAnalysis',
        formCount: `${parseInt(this.block.formCount) + 1}`
      })
      this.added = true
    }
  }
}
</script>
<style scoped>
.formfieldBorder {
  border: 2px solid blue;
  margin: 1em;
  padding: 8px;
}
h3 {
  font-weight: bold;
  color: blue;
}
</style>
