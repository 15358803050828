<template>
  <div class="stopwatch">
    <div>
      <b-form-group label="Briefly describe soil surface, soil cover.">
        <b-form-input
          type="text"
          v-model="surface"
          placeholder="average litter/residue for ring surface: sod, litter, bare, average, etc"
          size="lg"
          :state="surface != ''"
        />
      </b-form-group>
    </div>
    <div :class="timerclass" @click="toggle">
      <b-badge pill variant="light">{{ block.formCount }}</b-badge>
      <div class="toggletext">{{ toggletext }}</div>
      <div class="clocktext">
        <span>{{ clocktext }}</span>
      </div>
    </div>
    <div class="timings">
      <b-form-input
        type="text"
        v-model="fieldvalue"
        placeholder="timings will be recorded/edited here"
        size="lg"
      />
    </div>
    <b-button v-if="!added" @click="addAnotherForm"
      >Add another ring timer</b-button
    >
  </div>
</template>

<script>
export default {
  name: 'Timer',
  props: {
    block: Object
  },
  data() {
    return {
      added: false,
      ringname: 'ring' + this.block.formCount,
      fieldvalue: '',
      surface: 'unspecified',
      clock: null,
      running: false,
      key: 0,
      clocktext: '00:00',
      toggletext: 'START',
      classname: 'time'
    }
  },
  computed: {
    timerclass() {
      return this.running ? 'timer timer-running' : 'timer'
    },
    surfacevalue() {
      if (this.surface) {
        return ' for <i>' + this.surface + '</i> surface'
      } else {
        return ''
      }
    },
    namevalue() {
      return this.ringname + ': 1-inch timings' + this.surfacevalue
    }
  },
  methods: {
    addAnotherForm() {
      this.$emit('add-form', {
        component: 'Timer',
        formCount: `${parseInt(this.block.formCount) + 1}`
      })
      this.added = true
    },
    pad(val) {
      // pads values with zero
      return val > 9 ? val : '0' + val
    },
    formatTime(time) {
      var m = Math.floor(time / 60)
      var s = time % 60
      return this.pad(m) + ':' + this.pad(s)
    },

    toggle() {
      if (this.running) {
        this.stop()
        this.toggletext = 'START'
      } else {
        this.start()
        this.toggletext = 'STOP'
      }
    },
    start() {
      this.key += 1
      localStorage.setItem(
        'start' + this.block.formCount + 'ring' + this.key,
        new Date().getTime()
      )
      this.clock = setInterval(() => {
        var time = Math.floor(
          (new Date().getTime() -
            localStorage.getItem(
              'start' + this.block.formCount + 'ring' + this.key
            )) /
            1000
        )
        this.clocktext = this.formatTime(time)
      }, 1000)

      this.running = true
    },
    stop() {
      clearInterval(this.clock)
      var elapsed = Math.floor(
        (new Date().getTime() -
          localStorage.getItem(
            'start' + this.block.formCount + 'ring' + this.key
          )) /
          1000
      )
      this.fieldvalue += this.formatTime(elapsed)
      this.fieldvalue += ', '
      localStorage.removeItem(
        'start' + this.block.formCount + 'ring' + this.key
      )
      this.running = false
      this.clock = null
      this.clocktext = '00:00'
    }
  }
}
</script>

<style scoped>
.stopwatch {
  border-radius: 10px;
  border: 5px solid;
  padding: 3px;
  margin: 2px;
}
.ringnumber {
  background-color: #ff0;
}
.timer {
  border-radius: 20px;
  background: rgb(255, 80, 80);
  border: 5px solid;
  padding: 5px;
  margin: 2px;
  width: 220px;
  height: 130px;
  font-size: 54px;
  font-weight: bold;
  font-family: sans-serif;
  cursor: pointer;
  display: block;
}
.timer-running {
  background: #8bd12a;
}
.toggletext {
  font-size: 36px;
  display: block;
  position: relative;
  left: 75px;
  bottom: 80px;
}
.clocktext {
  font-size: 42px;
  position: relative;
  left: 70px;
  bottom: 72px;
  display: block;
}
.timings {
  font-size: 22px;
  padding: 2px;
  margin: 2px;
  font-weight: bold;
  display: inline;
  position: relative;
}
</style>
