import localforage from 'localforage'
export default {
  data() {
    return {
      siteTable: [],
      siteKeys: [],
      siteLength: null,
      siteOutbox: [],
      idObsTable: [],
      idObsKeys: [],
      idObsLength: null,
      idObsOutbox: [],
      obsTable: [],
      obsKeys: [],
      obsLength: null,
      obsOutbox: []
    }
  },
  async created() {
    // get table of sites
    this.siteTable = localforage.createInstance({
      name: 'soilhealthApp',
      storeName: 'siteTable'
    })
    this.siteLength = await this.siteTable.length()
    this.siteKeys = await this.siteTable.keys()
    for (var i = 0; i < this.siteKeys.length; i++) {
      this.siteOutbox.push([
        this.siteKeys[i],
        await this.siteTable.getItem(this.siteKeys[i])
      ])
    }
    // get a table of observations WITHOUT site id
    this.obsTable = localforage.createInstance({
      name: 'soilhealthApp',
      storeName: 'obsTable'
    })
    this.obsKeys = await this.obsTable.keys()
    this.obsLength = await this.obsTable.length()
    for (var i = 0; i < this.obsKeys.length; i++) {
      this.obsOutbox.push([
        this.obsKeys[i],
        await this.obsTable.getItem(this.obsKeys[i])
      ])
    }
    // get a table of observations WITH site id
    this.idObsTable = localforage.createInstance({
      name: 'soilhealthApp',
      storeName: 'idObsTable'
    })
    this.idObsKeys = await this.idObsTable.keys()
    this.idObsLength = await this.idObsTable.length()
    for (var i = 0; i < this.idObsKeys.length; i++) {
      this.idObsOutbox.push([
        this.idObsKeys[i],
        await this.idObsTable.getItem(this.idObsKeys[i])
      ])
    }
  }
}
