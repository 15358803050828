<template>
  <div id="app">
    <NavBar></NavBar>
    <router-view />
    <!-- <Maintenance /> -->
    <Footer />
  </div>
</template>
<script>
import NavBar from '@/components/NavBar.vue'
import Footer from '@/components/Footer.vue'
import Maintenance from '@/views/Maintenance.vue'
export default {
  components: {
    NavBar,
    Footer,
    Maintenance
  }
}
</script>
<style>
#nprogress .bar {
  height: 6px;
}
#nprogress .spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
#nprogress .spinner-icon {
  width: 200px;
  height: 200px;
  border: solid 7px transparent;
  border-top-color: #29d;
  border-left-color: #fff;
  border-radius: 50%;

  -webkit-animation: nprogress-spinner 800ms linear infinite;
  animation: nprogress-spinner 800ms linear infinite;
}
hr {
  border: none;
  height: 3px;
  background-color: #222;
}
</style>
