<template>
  <div class="formfieldBorder">
    <b-form-group label-for="range" :description="block.description">
      <div class="mt-2">
        {{ block.label }}:
        <strong>{{ fieldvalue }}</strong>
      </div>
      <b-form-input
        id="range"
        :state="state"
        v-model="fieldvalue"
        type="range"
        :min="block.min"
        :max="block.max"
      ></b-form-input>
      <b-form-invalid-feedback id="input-live-feedback">{{
        block.hint
      }}</b-form-invalid-feedback>
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'NumberInput',
  data() {
    return {
      fieldvalue: null
    }
  },
  computed: {
    state() {
      return Boolean(this.fieldvalue)
    }
  }
}
</script>
