<template>
  <b-container>
    <h1>Terms of Service and Privacy</h1>
    <hr />
    <h4>Summary</h4>
    <p>
      Who owns the content? Data or content you post on the service is yours,
      you assert your right to post it, and you can withdraw it at any time on
      request. By posting data or content you give us the right to reproduce it.
      See Rights.
    </p>

    <p>
      We use cookies. We do not sell or make available user information to other
      parties. See Privacy.
    </p>

    <p>
      Roles and Responsibilities of Soil Carbon Coalition, Project Coordinators,
      Observers/Users
    </p>

    <hr />
    <p>
      Please read these terms and conditions carefully before using
      soilhealth.app website (the "Services") operated by Soil Carbon Coalition
      ("We").
    </p>
    <p>
      We will provide the Services to You (the user), subject to the conditions
      stated below in this document. Every time you visit this website or use
      its services you accept the following conditions. This is why we urge you
      to read them carefully.
    </p>

    <h2 id="rights">Rights and Ownership</h2>

    <p>
      You retain your rights to any content you submit, post or display on or
      through the Services. Unless otherwise agreed in writing, by submitting,
      posting, or displaying content on or through the Services, you grant us a
      nonexclusive, royalty-free, worldwide, fully paid, and sublicensable
      license to use, reproduce, modify, adapt, publish, translate, create
      derivative works from, distribute, publicly perform and display your
      content and any name, username or likeness provided in connection with
      your content in all media formats and distribution methods now known or
      later developed on the Services.
    </p>
    <p>
      We need this license because You own your content and soilhealth.app
      therefore can't display it across its various surfaces (i.e., mobile, web)
      without your permission. This type of license also is needed to distribute
      your content across our Services. For example, you post an observation or
      story on soilhealth.app. It is reproduced as versions on both our web app
      and possibly through an API, and distributed to multiple places within
      soilhealth.app, such as the homepage or reading lists. A modification
      might be that we show a snippet of your work (and not the full post) in a
      preview, with attribution to you. A derivative work might be a list of top
      authors or quotes on soilhealth.app that uses portions of your content,
      again with full attribution. This license applies to our Services only,
      and does not grant us any permissions outside of our Services. So long as
      you comply with these Terms, soilhealth.app gives you a limited, personal,
      non-exclusive, and non-assignable license to access and use our Services.
      The Services are protected by copyright, trademark, and other US and
      foreign laws.
    </p>
    <p>
      These Terms don't grant you any right, title or interest in the Services,
      other users' content on the Services, or soilhealth.app trademarks, logos
      or other brand features. Separate and apart from the content you submit,
      post or display on our Services, we welcome feedback, including any
      comments, ideas and suggestions you have about our Services. We may use
      this feedback for any purpose, in our sole discretion, without any
      obligation to you. We may treat feedback as nonconfidential. We may stop
      providing the Services or any of its features within our sole discretion.
      We also retain the right to create limits on use and storage and may
      remove or limit content distribution on the Services.
    </p>
    <h3>Termination</h3>
    <p>
      You're free to stop using our Services at any time. We reserve the right
      to suspend or terminate your access to the Services with or without
      notice.
    </p>

    <h3>Transfer and Processing Data</h3>

    <p>
      In order for us to provide our Services, you agree that we may process,
      transfer and store information about you in the US and other countries,
      where you may not have the same rights and protections as you do under
      local law. Indemnification To the fullest extent permitted by applicable
      law, you will indemnify, defend and hold harmless Soil Carbon Coalition
      and our officers, directors, agents, partners, coordinators, and employees
      (individually and collectively, the “soilhealth.app Parties”) from and
      against any losses, liabilities, claims, demands, damages, expenses or
      costs (“Claims”) arising out of or related to your violation,
      misappropriation or infringement of any rights of another (including
      intellectual property rights or privacy rights) or your violation of the
      law.
    </p>
    <p>
      You agree to promptly notify us of any third-party Claims, cooperate with
      us in defending such Claims and pay all fees, costs and expenses
      associated with defending such Claims (including attorneys' fees). You
      also agree that the We will have control of the defense or settlement, at
      our sole option, of any third-party Claims.
    </p>
    <h3>Disclaimers — Service is “As Is”</h3>

    <p>
      We aim to give you great Services but there are some things we can't
      guarantee. Your use of our Services is at your sole risk. You understand
      that our Services and any content posted or shared by users on the
      Services are provided “as is” and “as available” without warranties of any
      kind, either express or implied, including implied warranties of
      merchantability, fitness for a particular purpose, title, and
      non-infringement. In addition, we don't represent or warrant that our
      Services are accurate, complete, reliable, current or error-free. No
      advice or information obtained from soilhealth.app or through the Services
      will create any warranty or representation not expressly made in this
      paragraph. soilhealth.app may provide information about third-party
      products, services, activities or events.
    </p>
    <h3>Limitation of Liability</h3>

    <p>
      We don't exclude or limit our liability to you where it would be illegal
      to do so; this includes any liability for the gross negligence, fraud or
      intentional misconduct on our part or with our partners in providing the
      Services. In countries where the following types of exclusions aren't
      allowed, we're responsible to you only for losses and damages that are a
      reasonably foreseeable result of our failure to use reasonable care and
      skill or our breach of our contract with you. This paragraph doesn't
      affect consumer rights that can't be waived or limited by any contract or
      agreement.
    </p>
    <p>
      In countries where exclusions or limitations of liability are allowed, We
      won't be liable for: (a) Any indirect, consequential, exemplary,
      incidental, punitive, or special damages, or any loss of use, data or
      profits, under any legal theory, even if we have been advised of the
      possibility of such damages. (b) Other than for the types of liability we
      can't limit by law (as described in this section), we limit our total
      liability for any claim arising out of or relating to these Terms or our
      Services, regardless of the form of the action, to the greater of $50.00
      USD or the amount paid by you to use our Services.
    </p>
    <h2 id="privacy">Privacy Policy</h2>
    <p>Effective March 1, 2021</p>
    <p>
      Before you continue using our website we advise you of our privacy policy
      regarding our user data collection. It will help you better understand our
      practices. In brief, the data and observations you post on soilhealth.app
      may be open and unprotected against unauthorized copying. Your email will
      NOT be disclosed unless it is part of your username.
    </p>

    <p>
      We keep logs of user access by IP. We also keep the email address and
      user-entered location of users who sign up with us.
    </p>
    <p>
      We do not sell or authorize anyone else to use emails or other personal
      information that we collect, such as username, IP address, etc. We do not
      provide or sell any user data or tracking information to third parties. We
      do not carry advertising.
    </p>
    <h3>Communications</h3>
    <p>
      The entire communication with us is electronic. Every time you send us an
      email or visit our website, you are going to be communicating with us. You
      hereby consent to receive communications from us. If you subscribe to any
      kind of notification on our website, you are going to receive emails from
      us. We will continue to communicate with you by posting news and notices
      on our website and by sending you emails. You also agree that all notices,
      disclosures, agreements and other communications we provide to you
      electronically meet the legal requirements that such communications be in
      writing.
    </p>
    <h3>Applicable Law</h3>
    <p>
      By visiting this website, you agree that the laws of your location,
      without regard to principles of conflict laws, will govern these terms and
      conditions, or any dispute of any sort that might come between Soil Carbon
      Coalition and you, or its partners and associates. See also
      <a href="#rights">Rights.</a>
    </p>
    <h3>Disputes</h3>
    <p>
      Any dispute related in any way to your visit to this website or to
      products or services you obtain from us shall be arbitrated by state or
      federal court in the state of Oregon and you consent to exclusive
      jurisdiction and venue of such courts.
    </p>
    <h3>Comments, Reviews, and Emails</h3>
    <p>
      Signed-in users (Observers) may post content as long as it is not obscene,
      illegal, defamatory, threatening, infringing of intellectual property
      rights, invasive of privacy or injurious in any other way to third
      parties. Content has to be free of software viruses, political
      campaigning, and commercial solicitation.
    </p>
    <p>
      We reserve all rights (but not the obligation) to remove and/or edit such
      content. When you post your content, you grant Soil Carbon Coalition
      non-exclusive, royalty-free and irrevocable right to use, reproduce,
      publish, modify such content throughout the world in any media.
    </p>
    <h3>License and Site Access</h3>
    <p>
      We grant you a limited license to access and make personal use of this
      website.
    </p>
    <h3>User Account</h3>
    <p>
      If you are an owner of an account on this website, you are solely
      responsible for maintaining the confidentiality of your private user
      details (email, location information, and password). You are responsible
      for all activities that occur under your account or password.
    </p>
    <p>
      You may request via email that your account, the data and observations you
      have posted, and all personal information be removed from this website.
    </p>
    <p>
      We reserve all rights to terminate accounts, edit or remove content and
      cancel orders in our sole discretion.
    </p>

    <h2 id="cookies">Cookies</h2>
    <p>
      This
      <b>Cookies Policy</b> explains what Cookies are and how We use them. You
      should read this policy so you can understand what type of cookies We use,
      or the information We collect using Cookies and how that information is
      used.
    </p>
    <p>
      Cookies do not typically contain any information that personally
      identifies a user, but personal information that we store about you may be
      linked to the information stored in and obtained from Cookies. For further
      information on how We use, store and keep your personal data secure, see
      our Privacy Policy.
    </p>
    <p>
      We do not store sensitive personal information, such as mailing addresses,
      account passwords, etc. in the Cookies We use.
    </p>

    <h4>Type of Cookies We Use</h4>
    <p>
      Cookies can be &quot;Persistent&quot; or &quot;Session&quot; Cookies.
      Persistent Cookies remain on your personal computer or mobile device when
      you go offline, while Session Cookies are deleted as soon as you close
      your web browser.
    </p>
    <p>
      We use both session and persistent Cookies for the purposes set out below:
    </p>
    <p>
      <strong>Necessary / Essential Cookies</strong>
    </p>
    <p>Type: Session Cookies</p>
    <p>Administered by: Us</p>
    <p>
      Purpose: These Cookies are essential to provide you with services
      available through the Website and to enable you to use some of its
      features. They help to authenticate users and prevent fraudulent use of
      user accounts. Without these Cookies, the services that you have asked for
      cannot be provided, and We only use these Cookies to provide you with
      those services.
    </p>
    <p>
      <strong>Functionality Cookies</strong>
    </p>
    <p>Type: Persistent Cookies</p>
    <p>Administered by: Us</p>
    <p>
      Purpose: These Cookies allow us to remember choices you make when you use
      the Website, such as remembering your login details or language
      preference. The purpose of these Cookies is to provide you with a more
      personal experience and to avoid you having to re-enter your preferences
      every time you use the Website.
    </p>
    <h3>Your Choices Regarding Cookies</h3>
    <p>
      If You prefer to avoid the use of Cookies on the Website, first You must
      disable the use of Cookies in your browser and then delete the Cookies
      saved in your browser associated with this website. You may use this
      option for preventing the use of Cookies at any time.
    </p>
    <p>
      If you do not accept Our Cookies, you may experience some inconvenience in
      your use of the Website and some features may not function properly.
    </p>
    <p>
      If you'd like to delete Cookies or instruct your web browser to delete or
      refuse Cookies, please visit the help pages of your web browser.
    </p>
    <p>
      For the Chrome web browser, please visit this page from Google:
      <a
        href="https://support.google.com/accounts/answer/32050"
        rel="external nofollow noopener"
        target="_blank"
        >https://support.google.com/accounts/answer/32050</a
      >
    </p>
    <p>
      For the Internet Explorer web browser, please visit this page from
      Microsoft:
      <a
        href="http://support.microsoft.com/kb/278835"
        rel="external nofollow noopener"
        target="_blank"
        >http://support.microsoft.com/kb/278835</a
      >
    </p>
    <p>
      For the Firefox web browser, please visit this page from Mozilla:
      <a
        href="https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored"
        rel="external nofollow noopener"
        target="_blank"
        >https://support.mozilla.org/en-US/kb/delete-cookies-remove-info-websites-stored</a
      >
    </p>
    <p>
      For the Safari web browser, please visit this page from Apple:
      <a
        href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac"
        rel="external nofollow noopener"
        target="_blank"
        >https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/mac</a
      >
    </p>
    <p>
      For any other web browser, please visit your web browser's official web
      pages.
    </p>

    <h3>Contact Us</h3>
    <p>
      If you have any questions about this Cookies Policy, You can contact us by
      email: info@soilcarboncoalition.org
    </p>
    <h2 id="roles">Roles and Responsibilities</h2>
    <h4>Soil Carbon Coalition</h4>
    <p>
      As operator of soilhealth.app, the Soil Carbon Coalition reserves the
      right to terminate any project or user account (including Project
      Coordinator accounts). While we take steps to protect data privacy, and
      protect Observations labeled private from unauthorized users, the security
      of Observations labeled Private also depends on the behavior of
      Observers/Users.
    </p>
    <p>
      While we do not currently charge users or projects for these services, we
      reserve the right to charge fees in the future for users or on a project
      basis, for duration of services and/or data storage.
    </p>
    <p>
      At times this website will be undergoing maintenance and may be
      unavailable to users.
    </p>
    <h4>Project Coordinators</h4>
    <p>
      Project Coordinators are designated by Soil Carbon Coalition, and will be
      given access to the Administrative section of soilhealth.app. Project
      coordinators may help register Observers (users) and give them access to
      their Project.
    </p>

    <p>
      The project coordinator role is fundamental, coordinating the web of
      relationships that the project consists of, toward a shared intelligence
      around the large forces and functions such as carbon cycling, water
      cycling, diversity and biodiversity, and local economics.
    </p>

    <p>
      There can be more than one project coordinator. Project coordinators can:
    </p>
    <ul>
      <li>
        register users as Observers into their project, where they can view and
        post Observations and Posts.
      </li>
      <li>
        curate or moderate Observations and Posts. Coordinators should watch for
        anything obscene, illegal, defamatory, threatening, infringing of
        intellectual property rights, invasive of privacy or injurious in any
        other way to third parties. Posted content has to be free of software
        viruses, political campaigning, and commercial solicitation.
      </li>
      <li>
        find, format, and post existing or legacy data that is relevant to their
        project engage people in data collecting, presentation, interpretation
        and dialogue, and formulating new questions
      </li>
      <li>
        engage schools, citizens, professionals, government and nonprofit
        people, media, and businesses in the above
      </li>
      <li>
        report actual results (for example, in the function of water or carbon
        cycling, diversity, economic resilience) to project funders and
        stakeholders
      </li>
      <li>
        Project coordinators can be volunteer, or paid (for example by local
        agencies or funders). The Soil Carbon Coalition does not expect to pay
        project coordinators, but to support their work via soilhealth.app and
        workshop sessions.
      </li>
    </ul>
    <h4>Observers/Users</h4>
    <p>
      If you wish to participate in a Project, you may contact the Project
      coordinator, who can set you up with a user account and the appropriate
      permissions. When you an Observer for a project, you will be able to post
      georeferenced Observations as well as Posts (short articles with words and
      pictures).
    </p>
    <p>
      Observers should take care to make and record Observations that are
      accurate, sufficiently well documented, and repeatable. The Observer is
      responsible for the accuracy of the measurement or observation, and the
      Project Coordinator can help with quality control and curation.
    </p>

    <h3>Contact Us</h3>
    <p>
      If you have any questions about Roles and Responsibilities, you can
      contact Peter Donovan, soilhealth.app administrator, by email:
      managingwholes.com@gmail.com
    </p>
  </b-container>
</template>
<script>
export default {
  /* eslint-disable */
  title: 'Terms of Service'
}
</script>
