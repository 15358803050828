export const contextMixin = {
  data() {
    return {
      authObserver: null,
      site: null
    }
  },
  computed: {
    projectName() {
      let str = this.$route.path.split('/')[1]
      // project name must start with capital letter,
      // all other links must start lowercase
      if (str.length && /^[A-Z]*$/.test(str.charAt(0))) {
        return str
      } else {
        return null
      }
    },
    status() {
      // a Boolean reactive property. If there's no projectName, no status
      if (this.authObserver) {
        return this.authObserver.my_projects.some(
          (item) => item.project_name === this.projectName
        )
      } else {
        return false
      }
    },
    projectID() {
      if (this.status) {
        return this.authObserver.my_projects.filter(
          (item) => item.project_name === this.projectName
        )[0].project_id
      } else {
        return null
      }
    }
  },
  methods: {
    logout() {
      localStorage.clear()
      window.location.href = 'https://soilhealth.app/accounts/logout/'
    },
    login() {
      window.location.href = 'https://soilhealth.app/accounts/login/'
    }
    // signup() {
    //   window.location.href = 'https://soilhealth.app/accounts/signup/'
    // }
  },
  created() {
    if (localStorage.authObserver) {
      this.authObserver = JSON.parse(localStorage.authObserver)
    }
    if (localStorage.site) {
      this.site = JSON.parse(localStorage.site)
    }
  }
}
