<template>
  <div class="formfieldBorder">
    <b-form-group
      label-for="link"
      :label="block.label"
      :description="block.description"
    >
      <b-form-input
        id="link"
        type="text"
        size="lg"
        v-model="fieldvalue"
        placeholder="URL"
        :state="fieldvalue.length >= 10"
      />
    </b-form-group>
  </div>
</template>

<script>
export default {
  props: {
    block: Object
  },
  name: 'Link',
  data() {
    return {
      fieldvalue: ''
    }
  },
  computed: {
    namevalue() {
      return '<a href="' + this.fieldvalue + '" target="_blank"></a>'
    }
  }
}
</script>
<style scoped>
.formfieldBorder {
  border: 2px solid blue;
  margin: 1em;
  padding: 8px;
}
h3 {
  font-weight: bold;
  color: blue;
}
</style>
